// helpers/constants
import { ENUMS } from './enums';
//////////////////////////////////////////////////

const dayToIsoDayNumberMap = {
  [ENUMS.ENUM_MONDAY]: 1,
  [ENUMS.ENUM_TUESDAY]: 2,
  [ENUMS.ENUM_WEDNESDAY]: 3,
  [ENUMS.ENUM_THURSDAY]: 4,
  [ENUMS.ENUM_FRIDAY]: 5,
  [ENUMS.ENUM_SATURDAY]: 6,
  [ENUMS.ENUM_SUNDAY]: 7,
};

const momentDayNumberMap = {
  [ENUMS.ENUM_SUNDAY]: 0,
  [ENUMS.ENUM_MONDAY]: 1,
  [ENUMS.ENUM_TUESDAY]: 2,
  [ENUMS.ENUM_WEDNESDAY]: 3,
  [ENUMS.ENUM_THURSDAY]: 4,
  [ENUMS.ENUM_FRIDAY]: 5,
  [ENUMS.ENUM_SATURDAY]: 6,
};

const shortDays = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];

const shortDaysToDayKeysMap = {
  'sun': ENUMS.ENUM_SUNDAY,
  'mon': ENUMS.ENUM_MONDAY,
  'tue': ENUMS.ENUM_TUESDAY,
  'wed': ENUMS.ENUM_WEDNESDAY,
  'thu': ENUMS.ENUM_THURSDAY,
  'fri': ENUMS.ENUM_FRIDAY,
  'sat': ENUMS.ENUM_SATURDAY,
};

const dayEnumToToShortDayKeysMap = {
  [ENUMS.ENUM_SUNDAY]: 'sun',
  [ENUMS.ENUM_MONDAY]: 'mon',
  [ENUMS.ENUM_TUESDAY]: 'tue',
  [ENUMS.ENUM_WEDNESDAY]: 'wed',
  [ENUMS.ENUM_THURSDAY]: 'thu',
  [ENUMS.ENUM_FRIDAY]: 'fri',
  [ENUMS.ENUM_SATURDAY]: 'sat',
};

export {
  shortDays,
  momentDayNumberMap,
  dayToIsoDayNumberMap,
  shortDaysToDayKeysMap,
  dayEnumToToShortDayKeysMap,
};
