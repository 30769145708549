export const NUMBER_REGEXP = /^\d+$/;
export const FLOAT_REGEXP = /^[+-]?\d+(\.\d+)?$/;
export const ROUTE_PATH_TEL_LIST_REGEXP = /\/route\/tel\/.*/;
export const ROUTE_PATH_CLO_LIST_REGEXP = /\/route\/clo\/.*/;
export const ROUTE_PATH_ROLES_EDIT_REGEXP = /\/roles\/.*\/edit/;
export const EMAIL_REGEXP = /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/;
export const ROUTE_PATH_CHARTS_EDIT_REGEXP = /\/charts\/.*\/edit/;
export const ROUTE_PATH_BRANCH_EDIT_REGEXP = /\/branch\/.*\/edit/;
export const ROUTE_PATH_REPORTS_EDIT_REGEXP = /\/reports\/.*\/edit/;
export const ROUTE_PATH_USERS_SETING_REGEXP = /\/users\/.*\/settings/;
export const ROUTE_PATH_CARRIERS_LIST_REGEXP = /\/carriers\/.*\/edit/;
export const EMAIL_REGEXP_GLOBAL = /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g;
export const ROUTE_PATH_SEQUENCES_LIST_REGEXP = /\/sequences\/.*\/edit/;
export const FILE_NAME_REGEXP = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
export const ROUTE_PATH_FLEET_TRUCKS_EDIT_REGEXP = /\/fleet\/truck\/.*\/edit/;
export const ROUTE_PATH_FLEET_VENDOR_EDIT_REGEXP = /\/fleet\/vendor\/.*\/edit/;
export const ROUTE_PATH_FLEET_DRIVERS_EDIT_REGEXP = /\/fleet\/driver\/.*\/edit/;
export const ROUTE_PATH_FLEET_TRAILERS_EDIT_REGEXP = /\/fleet\/trailer\/.*\/edit/;
// fleet-profile
export const ROUTE_PATH_FLEET_PROFILE_DRIVER_REGEXP = /\/fleet\/profile\/driver\/.*/;
export const DATE_REGEXP = /^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d/;
export const PHONE_NUMBER_REGEXP =
  /^(1[ \-\+]{0,3}|\+1[ -\+]{0,3}|\+1|\+)?((\(\+?1-[2-9][0-9]{1,2}\))|(\(\+?[2-8][0-9][0-9]\))|(\(\+?[1-9][0-9]\))|(\(\+?[17]\))|(\([2-9][2-9]\))|([ \-\.]{0,3}[0-9]{2,4}))?([ \-\.][0-9])?([ \-\.]{0,3}[0-9]{2,4}){2,3}$/; // eslint-disable-line
