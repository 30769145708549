import * as R from 'ramda';
//////////////////////////////////////////////////

const ENUM_US = 'US';
const ENUM_CA = 'CA';
const ENUM_MX = 'MX';

const COUNTRY_OPTIONS = [
  { label: 'USA', value: ENUM_US },
  { label: 'Canada', value: ENUM_CA },
  { label: 'Mexico', value: ENUM_MX },
];

const COUNTRY_DISPLAY_VALUE_MAP = {
  [ENUM_CA]: 'Canada',
  [ENUM_MX]: 'Mexico',
  [ENUM_US]: 'United States',
};

const COUNTRY_COORDINATES = {
  [ENUM_US]: { latitude: 37.0902, longitude: -95.7129 },
  [ENUM_CA]: { latitude: 56.1304, longitude: -106.3468 },
  [ENUM_MX]: { latitude: 23.6345, longitude: -102.5528 },
};

const USA_STATES = [
  {
    label: 'AL',
    value: 'ALABAMA',
    abbreviation: 'AL',
    labelFull: 'Alabama',
    coordinates: { latitude: 32.31823, longitude: -86.902298 },
  },
  {
    label: 'AK',
    value: 'ALASKA',
    abbreviation: 'AK',
    labelFull: 'Alaska',
    coordinates: { latitude: 66.160507, longitude: -153.369141 },
  },
  {
    label: 'AZ',
    value: 'ARIZONA',
    abbreviation: 'AZ',
    labelFull: 'Arizona',
    coordinates: { latitude: 34.048927, longitude: -111.093735 },
  },
  {
    label: 'AR',
    value: 'ARKANSAS',
    abbreviation: 'AR',
    labelFull: 'Arkansas',
    coordinates: { latitude: 34.799999, longitude: -92.199997 },
  },
  {
    label: 'CA',
    abbreviation: 'CA',
    value: 'CALIFORNIA',
    labelFull: 'California',
    coordinates: { latitude: 36.778259, longitude: -119.417931 },
  },
  {
    label: 'CO',
    value: 'COLORADO',
    abbreviation: 'CO',
    labelFull: 'Colorado',
    coordinates: { latitude: 39.113014, longitude: -105.358887 },
  },
  {
    label: 'CT',
    abbreviation: 'CT',
    value: 'CONNECTICUT',
    labelFull: 'Connecticut',
    coordinates: { latitude: 41.599998, longitude: -72.699997 },
  },
  {
    label: 'DE',
    value: 'DELAWARE',
    abbreviation: 'DE',
    labelFull: 'Delaware',
    coordinates: { latitude: 39.045753, longitude: -75.5 },
  },
  {
    label: 'DC',
    abbreviation: 'DC',
    value: 'DIST_COLUMBIA',
    labelFull: 'District Of Columbia',
    coordinates: { latitude: 38.9072, longitude: -77.0369 },
  },
  {
    label: 'FL',
    value: 'FLORIDA',
    abbreviation: 'FL',
    labelFull: 'Florida',
    coordinates: { latitude: 27.994402, longitude: -81.760254 },
  },
  {
    label: 'GA',
    value: 'GEORGIA',
    abbreviation: 'GA',
    labelFull: 'Georgia',
    coordinates: { latitude: 33.247875, longitude: -83.441162 },
  },
  {
    label: 'HI',
    value: 'HAWAII',
    abbreviation: 'HI',
    labelFull: 'Hawaii',
    coordinates: { latitude: 19.741755, longitude: -155.844437 },
  },
  {
    label: 'ID',
    value: 'IDAHO',
    abbreviation: 'ID',
    labelFull: 'Idaho',
    coordinates: { latitude: 44.068203, longitude: -114.742043 },
  },
  {
    label: 'IL',
    value: 'ILLINOIS',
    abbreviation: 'IL',
    labelFull: 'Illinois',
    coordinates: { latitude: 40.0, longitude: -89.0 },
  },
  {
    label: 'IN',
    value: 'INDIANA',
    abbreviation: 'IN',
    labelFull: 'Indiana',
    coordinates: { latitude: 40.273502, longitude: -86.126976 },
  },
  {
    label: 'IA',
    value: 'IOWA',
    labelFull: 'Iowa',
    abbreviation: 'IA',
    coordinates: { latitude: 42.032974, longitude: -93.581543 },
  },
  {
    label: 'KS',
    value: 'KANSAS',
    abbreviation: 'KS',
    labelFull: 'Kansas',
    coordinates: { latitude: 38.5, longitude: -98 },
  },
  {
    label: 'KY',
    value: 'KENTUCKY',
    abbreviation: 'KY',
    labelFull: 'Kentucky',
    coordinates: { latitude: 37.839333, longitude: -84.27002 },
  },
  {
    label: 'LA',
    abbreviation: 'LA',
    value: 'LOUISIANA',
    labelFull: 'Louisiana',
    coordinates: { latitude: 30.39183, longitude: -92.329102 },
  },
  {
    label: 'ME',
    value: 'MAINE',
    abbreviation: 'ME',
    labelFull: 'Maine',
    coordinates: { latitude: 45.367584, longitude: -68.972168 },
  },
  {
    label: 'MD',
    value: 'MARYLAND',
    abbreviation: 'MD',
    labelFull: 'Maryland',
    coordinates: { latitude: 39.045753, longitude: -76.641273 },
  },
  {
    label: 'MA',
    abbreviation: 'MA',
    value: 'MASSACHUSETTS',
    labelFull: 'Massachusetts',
    coordinates: { latitude: 42.407211, longitude: -71.382439 },
  },
  {
    label: 'MI',
    value: 'MICHIGAN',
    abbreviation: 'MI',
    labelFull: 'Michigan',
    coordinates: { latitude: 44.182205, longitude: -84.506836 },
  },
  {
    label: 'MN',
    abbreviation: 'MN',
    value: 'MINNESOTA',
    labelFull: 'Minnesota',
    coordinates: { latitude: 46.39241, longitude: -94.63623 },
  },
  {
    label: 'MS',
    abbreviation: 'MS',
    value: 'MISSISSIPPI',
    labelFull: 'Mississippi',
    coordinates: { latitude: 33.0, longitude: -90.0 },
  },
  {
    label: 'MO',
    value: 'MISSOURI',
    abbreviation: 'MO',
    labelFull: 'Missouri',
    coordinates: { latitude: 38.573936, longitude: -92.60376 },
  },
  {
    label: 'MT',
    value: 'MONTANA',
    abbreviation: 'MT',
    labelFull: 'Montana',
    coordinates: { latitude: 46.96526, longitude: -109.533691 },
  },
  {
    label: 'NE',
    value: 'NEBRASKA',
    abbreviation: 'NE',
    labelFull: 'Nebraska',
    coordinates: { latitude: 41.5, longitude: -100 },
  },
  {
    label: 'NV',
    value: 'NEVADA',
    abbreviation: 'NV',
    labelFull: 'Nevada',
    coordinates: { latitude: 39.876019, longitude: -117.224121 },
  },
  {
    label: 'NH',
    abbreviation: 'NH',
    value: 'NEW_HAMPSHIRE',
    labelFull: 'New Hampshire',
    coordinates: { latitude: 44.0, longitude: -71.5 },
  },
  {
    label: 'NJ',
    abbreviation: 'NJ',
    value: 'NEW_JERSEY',
    labelFull: 'New Jersey',
    coordinates: { latitude: 39.833851, longitude: -74.871826 },
  },
  {
    label: 'NM',
    abbreviation: 'NM',
    value: 'NEW_MEXICO',
    labelFull: 'New Mexico',
    coordinates: { latitude: 34.307144, longitude: -106.018066 },
  },
  {
    label: 'NY',
    value: 'NEW_YORK',
    abbreviation: 'NY',
    labelFull: 'New York',
    coordinates: { latitude: 43.0, longitude: -75.0 },
  },
  {
    label: 'NC',
    abbreviation: 'NC',
    value: 'NORTH_CAROLINA',
    labelFull: 'North Carolina',
    coordinates: { latitude: 35.782169, longitude: -80.793457 },
  },
  {
    label: 'ND',
    abbreviation: 'ND',
    value: 'NORTH_DAKOTA',
    labelFull: 'North Dakota',
    coordinates: { latitude: 47.650589, longitude: -100.437012 },
  },
  {
    label: 'OH',
    value: 'OHIO',
    labelFull: 'Ohio',
    abbreviation: 'OH',
    coordinates: { latitude: 40.367474, longitude: -82.996216 },
  },
  {
    label: 'OK',
    value: 'OKLAHOMA',
    abbreviation: 'OK',
    labelFull: 'Oklahoma',
    coordinates: { latitude: 36.084621, longitude: -96.921387 },
  },
  {
    label: 'OR',
    value: 'OREGON',
    abbreviation: 'OR',
    labelFull: 'Oregon',
    coordinates: { latitude: 44.0, longitude: -120.5 },
  },
  {
    label: 'PA',
    abbreviation: 'PA',
    value: 'PENNSYLVANIA',
    labelFull: 'Pennsylvania',
    coordinates: { latitude: 41.203323, longitude: -77.194527 },
  },
  {
    label: 'RI',
    abbreviation: 'RI',
    value: 'RHODE_ISLAND',
    labelFull: 'Rhode Island',
    coordinates: { latitude: 41.742325, longitude: -71.742332 },
  },
  {
    label: 'SC',
    abbreviation: 'SC',
    value: 'SOUTH_CAROLINA',
    labelFull: 'South Carolina',
    coordinates: { latitude: 33.836082, longitude: -81.163727 },
  },
  {
    label: 'SD',
    abbreviation: 'SD',
    value: 'SOUTH_DAKOTA',
    labelFull: 'South Dakota',
    coordinates: { latitude: 44.5, longitude: -100 },
  },
  {
    label: 'TN',
    abbreviation: 'TN',
    value: 'TENNESSEE',
    labelFull: 'Tennessee',
    coordinates: { latitude: 35.860119, longitude: -86.660156 },
  },
  {
    label: 'TX',
    value: 'TEXAS',
    abbreviation: 'TX',
    labelFull: 'Texas',
    coordinates: { latitude: 31.0, longitude: -100.0 },
  },
  {
    label: 'UT',
    value: 'UTAH',
    labelFull: 'Utah',
    abbreviation: 'UT',
    coordinates: { latitude: 39.41922, longitude: -111.950684 },
  },
  {
    label: 'VT',
    value: 'VERMONT',
    abbreviation: 'VT',
    labelFull: 'Vermont',
    coordinates: { latitude: 44.0, longitude: -72.699997 },
  },
  {
    label: 'VA',
    value: 'VIRGINIA',
    abbreviation: 'VA',
    labelFull: 'Virginia',
    coordinates: { latitude: 37.926868, longitude: -78.024902 },
  },
  {
    label: 'WA',
    abbreviation: 'WA',
    value: 'WASHINGTON',
    labelFull: 'Washington',
    coordinates: { latitude: 47.751076, longitude: -120.740135 },
  },
  {
    label: 'WV',
    abbreviation: 'WV',
    value: 'WEST_VIRGINIA',
    labelFull: 'West Virginia',
    coordinates: { latitude: 39.0, longitude: -80.5 },
  },
  {
    label: 'WI',
    abbreviation: 'WI',
    value: 'WISCONSIN',
    labelFull: 'Wisconsin',
    coordinates: { latitude: 44.5, longitude: -89.5 },
  },
  {
    label: 'WY',
    value: 'WYOMING',
    abbreviation: 'WY',
    labelFull: 'Wyoming',
    coordinates: { latitude: 43.07597, longitude: -107.290283 },
  },
];

const CANADA_PROVINCES = [
  {
    label: 'AB',
    value: 'ALBERTA',
    abbreviation: 'AB',
    labelFull: 'Alberta',
    coordinates: { latitude: 53.01669802, longitude: -112.8166386 },
  },
  {
    label: 'BC',
    abbreviation: 'BC',
    value: 'BRITISH_COLUMBIA',
    labelFull: 'British Columbia',
    coordinates: { latitude: 49.09996035, longitude: -116.516697 },
  },
  {
    label: 'MB',
    value: 'MANITOBA',
    abbreviation: 'MB',
    labelFull: 'Manitoba',
    coordinates: { latitude: 50.15002545, longitude: -96.88332178 },
  },
  {
    label: 'NB',
    abbreviation: 'NB',
    value: 'NEW_BRUNSWICK',
    labelFull: 'New Brunswick',
    coordinates: { latitude: 45.26704185, longitude: -66.07667505 },
  },
  {
    label: 'NL',
    abbreviation: 'NL',
    value: 'NEWFOUNDLAND_AND_LABRADOR',
    labelFull: 'Newfoundland and Labrador',
    coordinates: { latitude: 49.17440025, longitude: -57.42691878 },
  },
  {
    label: 'NT',
    abbreviation: 'NT',
    value: 'NORTHWEST_TERRITORIES',
    labelFull: 'Northwest Territories',
    coordinates: { latitude: 62.40005292, longitude: -110.7333291 },
  },
  {
    label: 'NS',
    abbreviation: 'NS',
    value: 'NOVA_SCOTIA',
    labelFull: 'Nova Scotia',
    coordinates: { latitude: 45.58327578, longitude: -62.63331934 },
  },
  {
    label: 'NU',
    value: 'NUNAVUT',
    abbreviation: 'NU',
    labelFull: 'Nunavut',
    coordinates: { latitude: 68.76746684, longitude: -81.23608303 },
  },
  {
    label: 'ON',
    value: 'ONTARIO',
    abbreviation: 'ON',
    labelFull: 'Ontario',
    coordinates: { latitude: 44.56664532, longitude: -80.84998519 },
  },
  {
    label: 'PE',
    abbreviation: 'PE',
    value: 'PRINCE_EDWARD_ISLAND',
    labelFull: 'Prince Edward Island',
    coordinates: { latitude: 46.24928164, longitude: -63.13132512 },
  },
  {
    label: 'QC',
    value: 'QUEBEC',
    abbreviation: 'QC',
    labelFull: 'Quebec',
    coordinates: { latitude: 49.82257774, longitude: -64.34799504 },
  },
  {
    label: 'SK',
    abbreviation: 'SK',
    value: 'SASKATCHEWAN',
    labelFull: 'Saskatchewan',
    coordinates: { latitude: 50.93331097, longitude: -102.7999891 },
  },
  {
    label: 'YT',
    value: 'YUKON',
    labelFull: 'Yukon',
    abbreviation: 'YT',
    coordinates: { latitude: 61.35037539, longitude: -139.0000017 },
  },
];

const MEXICO_STATES = [
  {
    label: 'DF',
    abbreviation: 'DF',
    value: 'DISTRITO_FEDERAL',
    labelFull: 'Distrito Federal',
    additionalMatch: 'Mexico City',
    coordinates: { latitude: 19.44244244, longitude: -99.1309882 },
  },
  {
    label: 'AG',
    abbreviation: 'AG',
    value: 'AGUASCALIENTES',
    labelFull: 'Aguascalientes',
    coordinates: { latitude: 21.87945992, longitude: -102.2904135 },
  },
  {
    label: 'BC',
    abbreviation: 'BC',
    value: 'BAJA_CALIFORNIA_NORTE',
    labelFull: 'Baja California Norte',
    coordinates: { latitude: 30.76405113, longitude: -116.0092603 },
  },
  {
    label: 'BS',
    abbreviation: 'BS',
    value: 'BAJA_CALIFORNIA_SUR',
    labelFull: 'Baja California Sur',
    coordinates: { latitude: 26.01333335, longitude: -111.3516635 },
  },
  {
    label: 'CM',
    value: 'CAMPECHE',
    abbreviation: 'CM',
    labelFull: 'Campeche',
    coordinates: { latitude: 18.65365928, longitude: -91.82448019 },
  },
  {
    label: 'CS',
    value: 'CHIAPAS',
    abbreviation: 'CS',
    labelFull: 'Chiapas',
    coordinates: { latitude: 16.74999697, longitude: -92.63337447 },
  },
  {
    label: 'CH',
    abbreviation: 'CH',
    value: 'CHIHUAHUA',
    labelFull: 'Chihuahua',
    coordinates: { latitude: 26.93335472, longitude: -105.6666358 },
  },
  {
    label: 'CO',
    value: 'COAHUILA',
    abbreviation: 'CO',
    labelFull: 'Coahuila',
    coordinates: { latitude: 28.32998781, longitude: -100.8499789 },
  },
  {
    label: 'CL',
    value: 'COLIMA',
    abbreviation: 'CL',
    labelFull: 'Colima',
    coordinates: { latitude: 18.92038129, longitude: -103.8799748 },
  },
  {
    label: 'DG',
    value: 'DURANGO',
    abbreviation: 'DG',
    labelFull: 'Durango',
    coordinates: { latitude: 25.57005292, longitude: -103.5000238 },
  },
  {
    label: 'GT',
    abbreviation: 'GT',
    value: 'GUANAJUATO',
    labelFull: 'Guanajuato',
    coordinates: { latitude: 20.67001609, longitude: -101.4999909 },
  },
  {
    label: 'GR',
    value: 'GUERRERO',
    abbreviation: 'GR',
    labelFull: 'Guerrero',
    coordinates: { latitude: 17.54997398, longitude: -99.5000096 },
  },
  {
    label: 'HG',
    value: 'HIDALGO',
    abbreviation: 'HG',
    labelFull: 'Hidalgo',
    coordinates: { latitude: 20.17043418, longitude: -98.73003076 },
  },
  {
    label: 'JA',
    value: 'JALISCO',
    abbreviation: 'JA',
    labelFull: 'Jalisco',
    coordinates: { latitude: 19.77001935, longitude: -104.3699966 },
  },
  {
    label: 'MX',
    value: 'MEXICO',
    abbreviation: 'MX',
    labelFull: 'Mexico',
    coordinates: { latitude: 19.41001548, longitude: -99.02998661 },
  },
  {
    label: 'MI',
    abbreviation: 'MI',
    value: 'MICHOACAN',
    labelFull: 'Michoacan',
    coordinates: { latitude: 19.67997316, longitude: -100.569996 },
  },
  {
    label: 'MO',
    value: 'MORELOS',
    abbreviation: 'MO',
    labelFull: 'Morelos',
    coordinates: { latitude: 18.92110476, longitude: -99.23999964 },
  },
  {
    label: 'NA',
    value: 'NAYARIT',
    abbreviation: 'NA',
    labelFull: 'Nayarit',
    coordinates: { latitude: 21.81999758, longitude: -105.2200481 },
  },
  {
    label: 'NL',
    abbreviation: 'NL',
    value: 'NUEVO_LEON',
    labelFull: 'Nuevo Leon',
    coordinates: { latitude: 25.1899986, longitude: -99.83998885 },
  },
  {
    label: 'OA',
    value: 'OAXACA',
    abbreviation: 'OA',
    labelFull: 'Oaxaca',
    coordinates: { latitude: 16.42999066, longitude: -95.01999882 },
  },
  {
    label: 'PU',
    value: 'PUEBLA',
    abbreviation: 'PU',
    labelFull: 'Puebla',
    coordinates: { latitude: 18.90002077, longitude: -98.44999618 },
  },
  {
    label: 'QT',
    abbreviation: 'QT',
    value: 'QUERETARO',
    labelFull: 'Queretaro',
    coordinates: { latitude: 20.37998212, longitude: -100.0000308 },
  },
  {
    label: 'QR',
    abbreviation: 'QR',
    value: 'QUINTANA_ROO',
    labelFull: 'Quintana Roo',
    coordinates: { latitude: 21.20839057, longitude: -86.7114549 },
  },
  {
    label: 'SL',
    abbreviation: 'SL',
    value: 'SAN_LUIS_POTOSI',
    labelFull: 'San Luis Potosi',
    coordinates: { latitude: 22.00001243, longitude: -99.66999923 },
  },
  {
    label: 'SI',
    value: 'SINALOA',
    abbreviation: 'SI',
    labelFull: 'Sinaloa',
    coordinates: { latitude: 23.19999086, longitude: -106.2300381 },
  },
  {
    label: 'SO',
    value: 'SONORA',
    abbreviation: 'SO',
    labelFull: 'Sonora',
    coordinates: { latitude: 27.58000775, longitude: -109.9299931 },
  },
  {
    label: 'TB',
    value: 'TABASCO',
    abbreviation: 'TB',
    labelFull: 'Tabasco',
    coordinates: { latitude: 18.40002545, longitude: -93.22997888 },
  },
  {
    label: 'TM',
    abbreviation: 'TM',
    value: 'TAMAULIPAS',
    labelFull: 'Tamaulipas',
    coordinates: { latitude: 22.73335268, longitude: -98.95001734 },
  },
  {
    label: 'TL',
    value: 'TLAXCALA',
    abbreviation: 'TL',
    labelFull: 'Tlaxcala',
    coordinates: { latitude: 19.31999514, longitude: -98.2300096 },
  },
  {
    label: 'VE',
    value: 'VERACRUZ',
    abbreviation: 'VE',
    labelFull: 'Veracruz',
    coordinates: { latitude: 17.93997601, longitude: -94.73999007 },
  },
  {
    label: 'YU',
    value: 'YUCATAN',
    abbreviation: 'YU',
    labelFull: 'Yucatan',
    coordinates: { latitude: 21.09998985, longitude: -89.27998743 },
  },
  {
    label: 'ZT',
    abbreviation: 'ZT',
    value: 'ZACATECAS',
    labelFull: 'Zacatecas',
    coordinates: { latitude: 22.35001691, longitude: -102.88001 },
  },
];

const STATE_OPTIONS_MAP = {
  NO_COUNTRY: [],
  US: USA_STATES,
  MX: MEXICO_STATES,
  CA: CANADA_PROVINCES,
};

const getAbbreviations = R.compose(
  R.map(R.prop('abbreviation')),
  R.indexBy(R.prop('value')),
);

const STATE_ABBREVIATION_MAP = {
  US: getAbbreviations(USA_STATES),
  MX: getAbbreviations(MEXICO_STATES),
  CA: getAbbreviations(CANADA_PROVINCES),
};

export {
  ENUM_US,
  ENUM_CA,
  ENUM_MX,
  USA_STATES,
  MEXICO_STATES,
  COUNTRY_OPTIONS,
  CANADA_PROVINCES,
  STATE_OPTIONS_MAP,
  COUNTRY_COORDINATES,
  STATE_ABBREVIATION_MAP,
  COUNTRY_DISPLAY_VALUE_MAP,
};
