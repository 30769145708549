import * as R from 'ramda';
// constants
import { FIELD_VALUE } from './system-fields';
//////////////////////////////////////////////////

export const SHIPMENT_LIMIT = 5000;

export const WS_CONNECTION_ERROR = 'ws-connection-error';

export const LOGIN_EXTERNAL_LOAD_BOARD_TYPE = 'type';
export const LOGIN_EXTERNAL_LOAD_BOARD_LOGIN = 'login';
export const EXTERNAL_LOAD_BOARD_NAME = 'loadBoardName';
export const LOGIN_EXTERNAL_LOAD_BOARD_SOURCE = 'source';
export const LOGIN_EXTERNAL_LOAD_BOARD_PASSWORD = 'password';
export const EXTERNAL_LOAD_BOARD_PICKUP_FREQUENCY_DAILY = 'DAILY';
export const EXTERNAL_LOAD_BOARD_PICKUP_FREQUENCY_WEEKLY = 'WEEKLY';
export const EXTERNAL_LOAD_BOARD_PICKUP_FREQUENCY_MONTHLY = 'MONTHLY';
export const EXTERNAL_LOAD_BOARD_BOOK_IT_NOW_PRICE = 'bookItNowPrice';

// loadboards
export const EXTERNAL_LOAD_BOARD_DAT = 'DAT';
export const EXTERNAL_LOAD_BOARD_UBER = 'UBER';
export const EXTERNAL_LOAD_BOARD_LB123 = 'LB123';
export const EXTERNAL_LOAD_BOARD_PARADE = 'PARADE';
export const EXTERNAL_LOAD_BOARD_COYOTE = 'COYOTE';
export const EXTERNAL_LOAD_BOARD_NEWTRUL = 'NEWTRUL';
export const EXTERNAL_LOAD_BOARD_TRUCK_STOP = 'TRUCKSTOP';
export const EXTERNAL_LOAD_BOARD_CH_ROBINSON = 'CH_ROBINSON';
export const EXTERNAL_LOAD_BOARD_TRUCKER_TOOLS = 'TRUCKER_TOOLS';

export const EXTERNAL_LOAD_BOARD_LIST_OPTIONS = [
  { value: '', label: '' },
  { value: EXTERNAL_LOAD_BOARD_COYOTE, label: 'Coyote' },
  { value: EXTERNAL_LOAD_BOARD_NEWTRUL, label: 'Newtrul' },
  { value: EXTERNAL_LOAD_BOARD_PARADE, label: 'Parade.ai' },
  { value: EXTERNAL_LOAD_BOARD_UBER, label: 'Uber Freight' },
  { value: EXTERNAL_LOAD_BOARD_LB123, label: '123Loadboard' },
  { value: EXTERNAL_LOAD_BOARD_TRUCK_STOP, label: 'Truckstop' },
  { value: EXTERNAL_LOAD_BOARD_DAT, label: EXTERNAL_LOAD_BOARD_DAT },
  { value: EXTERNAL_LOAD_BOARD_CH_ROBINSON, label: 'C.H. Robinson' },
  { value: EXTERNAL_LOAD_BOARD_TRUCKER_TOOLS, label: 'Trucker Tools' },
];

export const EXTERNAL_LOAD_BOARDS_DISPLAY_VALUES_MAP = {
  [EXTERNAL_LOAD_BOARD_COYOTE]: 'Coyote',
  [EXTERNAL_LOAD_BOARD_NEWTRUL]: 'Newtrul',
  [EXTERNAL_LOAD_BOARD_PARADE]: 'Parade.ai',
  [EXTERNAL_LOAD_BOARD_UBER]: 'Uber Freight',
  [EXTERNAL_LOAD_BOARD_LB123]: '123Loadboard',
  [EXTERNAL_LOAD_BOARD_TRUCK_STOP]: 'Truckstop',
  [EXTERNAL_LOAD_BOARD_DAT]: EXTERNAL_LOAD_BOARD_DAT,
  [EXTERNAL_LOAD_BOARD_CH_ROBINSON]: 'C.H. Robinson',
  [EXTERNAL_LOAD_BOARD_TRUCKER_TOOLS]: 'Trucker Tools',
};

export const EXTERNAL_LOGIN_LOAD_BOARD_LIST_OPTIONS = [
  { value: '', label: '' },
  { value: EXTERNAL_LOAD_BOARD_DAT, label: EXTERNAL_LOAD_BOARD_DAT },
  { value: EXTERNAL_LOAD_BOARD_UBER, label: 'Uber Freight' },
  { value: EXTERNAL_LOAD_BOARD_LB123, label: '123Loadboard' },
  { value: EXTERNAL_LOAD_BOARD_TRUCKER_TOOLS, label: 'Trucker Tools' },
];

export const EXTERNAL_LOAD_BOARD_CONFIGURATION_LIST_OPTIONS = [
  { value: '', label: '' },
  { value: EXTERNAL_LOAD_BOARD_COYOTE, label: 'Coyote' },
  { value: EXTERNAL_LOAD_BOARD_TRUCK_STOP, label: 'Truckstop' },
  { value: EXTERNAL_LOAD_BOARD_CH_ROBINSON, label: 'C.H. Robinson' },
  { value: EXTERNAL_LOAD_BOARD_TRUCKER_TOOLS, label: 'Trucker Tools' },
  { value: EXTERNAL_LOAD_BOARD_NEWTRUL, label: 'Newtrul' },
  { value: EXTERNAL_LOAD_BOARD_PARADE, label: 'Parade.ai' },
];

export const EXTERNAL_LOAD_BOARDS_TO_SEARCH = [
  EXTERNAL_LOAD_BOARD_DAT,
  EXTERNAL_LOAD_BOARD_UBER,
  EXTERNAL_LOAD_BOARD_LB123,
  EXTERNAL_LOAD_BOARD_COYOTE,
  EXTERNAL_LOAD_BOARD_TRUCK_STOP,
  EXTERNAL_LOAD_BOARD_CH_ROBINSON,
];

export const EXTERNAL_LOAD_BOARDS_TO_SEARCH_OPTIONS = R.filter(
  R.compose(R.flip(R.includes)(EXTERNAL_LOAD_BOARDS_TO_SEARCH), R.prop('value')),
  EXTERNAL_LOAD_BOARD_LIST_OPTIONS,
);

export const EXTERNAL_LOAD_BOARDS_TO_POST = [
  EXTERNAL_LOAD_BOARD_DAT,
  EXTERNAL_LOAD_BOARD_PARADE,
  EXTERNAL_LOAD_BOARD_NEWTRUL,
  EXTERNAL_LOAD_BOARD_TRUCK_STOP,
  EXTERNAL_LOAD_BOARD_TRUCKER_TOOLS,
];

export const EXTERNAL_LOAD_BOARD_AVAILABLE_LIST = [
  EXTERNAL_LOAD_BOARD_DAT,
  EXTERNAL_LOAD_BOARD_UBER,
  EXTERNAL_LOAD_BOARD_TRUCK_STOP,
];

export const EXTERNAL_LOAD_BOARDS_TO_BOOK = [
  EXTERNAL_LOAD_BOARD_UBER,
  EXTERNAL_LOAD_BOARD_CH_ROBINSON,
];

export const EXTERNAL_LOAD_BOARD_PICKUP_FREQUENCY_LOCALE_KEYS = {
  [EXTERNAL_LOAD_BOARD_PICKUP_FREQUENCY_DAILY]: 'titles:daily',
  [EXTERNAL_LOAD_BOARD_PICKUP_FREQUENCY_WEEKLY]: 'titles:weekly',
  [EXTERNAL_LOAD_BOARD_PICKUP_FREQUENCY_MONTHLY]: 'titles:monthly',
};

export const LOAD_BOARD_OAUTH2_MAP = {
  [EXTERNAL_LOAD_BOARD_UBER]: {
    localhost: {
      REDIRECT_URI: 'http://localhost:8080/oauth2',
      CLIENT_ID: 'AkA6ohAYwahNksGDscy40a326MVYe0F_',
      AUTH_SERVICE: 'https://login.uber.com/oauth/v2/authorize',
    },
    localDevelopment: {
      REDIRECT_URI: 'http://localhost:8080/oauth2',
      CLIENT_ID: 'AkA6ohAYwahNksGDscy40a326MVYe0F_',
      AUTH_SERVICE: 'https://login.uber.com/oauth/v2/authorize',
    },
    development: {
      REDIRECT_URI: 'https://devamoustms.com/oauth2',
      CLIENT_ID: 'AkA6ohAYwahNksGDscy40a326MVYe0F_',
      AUTH_SERVICE: 'https://login.uber.com/oauth/v2/authorize',
    },
    testing: {
      REDIRECT_URI: 'https://devamoustms.com/oauth2',
      CLIENT_ID: 'AkA6ohAYwahNksGDscy40a326MVYe0F_',
      AUTH_SERVICE: 'https://login.uber.com/oauth/v2/authorize',
    },
    staging: {
      REDIRECT_URI: 'https://testamoustms.com/oauth2',
      CLIENT_ID: 'AkA6ohAYwahNksGDscy40a326MVYe0F_',
      AUTH_SERVICE: 'https://login.uber.com/oauth/v2/authorize',
    },
    production: {
      REDIRECT_URI: 'https://amoustms.com/oauth2',
      CLIENT_ID: 'AkA6ohAYwahNksGDscy40a326MVYe0F_',
      AUTH_SERVICE: 'https://login.uber.com/oauth/v2/authorize',
    },
  },
  [EXTERNAL_LOAD_BOARD_LB123]: {
    localhost: {
      SCOPE: 'loadsearching',
      CLIENT_ID: '10433128708203738229',
      REDIRECT_URI: 'http://localhost:8080/oauth2',
      RESOURCE: 'C335E733-3E3A-43DA-87EB-CFF42A50F57B',
      AUTH_SERVICE: 'https://api.dev.123loadboard.com/authorize',
    },
    localDevelopment: {
      SCOPE: 'loadsearching',
      CLIENT_ID: '10433128708203738229',
      REDIRECT_URI: 'http://localhost:8080/oauth2',
      RESOURCE: 'C335E733-3E3A-43DA-87EB-CFF42A50F57B',
      AUTH_SERVICE: 'https://api.dev.123loadboard.com/authorize',
    },
    development: {
      SCOPE: 'loadsearching',
      CLIENT_ID: '10433128708203738229',
      REDIRECT_URI: 'https://devamoustms.com/oauth2',
      RESOURCE: 'C335E733-3E3A-43DA-87EB-CFF42A50F57B',
      AUTH_SERVICE: 'https://api.dev.123loadboard.com/authorize',
    },
    testing: {
      SCOPE: 'loadsearching',
      CLIENT_ID: '10433128708203738229',
      REDIRECT_URI: 'https://devamoustms.com/oauth2',
      RESOURCE: '0B5C5A4E-C9B2-4743-88A9-EA48BD4D52DE',
      AUTH_SERVICE: 'https://api.dev.123loadboard.com/authorize',
    },
    staging: {
      SCOPE: 'loadsearching',
      CLIENT_ID: '10433128708203738229',
      REDIRECT_URI: 'https://testamoustms.com/oauth2',
      RESOURCE: 'C335E733-3E3A-43DA-87EB-CFF42A50F57B',
      AUTH_SERVICE: 'https://api.dev.123loadboard.com/authorize',
    },
    production: {
      SCOPE: 'loadsearching',
      CLIENT_ID: '03206392912743618240', // TODO: check
      REDIRECT_URI: 'https://amoustms.com/oauth2',
      RESOURCE: '0B5C5A4E-C9B2-4743-88A9-EA48BD4D52DE',
      AUTH_SERVICE: 'https://api.123loadboard.com/authorize',
    },
  },
};

// load board statuses
export const LB_STATUS_ACTIVE = 'ACTIVE';
export const LB_STATUS_FAILED = 'FAILED';
export const LB_STATUS_DISABLED = 'DISABLED';

export const LB_STATUS_MUST_BE_CONFIGURED = 'MUST_BE_CONFIGURED';
// load board shipment statuses
export const LB_SHIPMENT_STATUS_SAVED = 'SAVED';
export const LB_SHIPMENT_STATUS_COMMITTED = 'COMMITTED';

export const truckOptions = [
  { value: 'AUTO_CARRIER', label: 'Auto Carrier' },
  { value: 'CONTAINER', label: 'Container' },
  { value: 'CONTAINER_INSULATED', label: 'Container, Insulated' },
  { value: 'CONTAINER_REFRIGERATED', label: 'Container, Refrigerated' },
  { value: 'DOUBLE_DROP', label: 'Double Drop' },
  { value: 'DROP_DECK_LANDOLL', label: 'Drop Deck, Landoll' },
  { value: 'DUMP_TRAILER', label: 'Dump Trailer' },
  { value: 'FLATBED', label: 'Flatbed' },
  { value: 'FLATBED_AIR_RIDE', label: 'Flatbed, Air-Ride' },
  { value: 'FLATBED_B_TRAIN', label: 'Flatbed, B-Train' },
  { value: 'FLATBED_DOUBLE', label: 'Flatbed, Double' },
  { value: 'FLATBED_HAZ_MAT', label: 'Flatbed, HazMat' },
  { value: 'FLATBED_HOTSHOT', label: 'Flatbed, Hotshot' },
  { value: 'FLATBED_MAXI', label: 'Flatbed, Maxi' },
  { value: 'FLATBED_W_SIDES', label: 'Flatbed w/Sides' },
  { value: 'FLATBED_W_TARPS', label: 'Flatbed w/Tarps' },
  { value: 'FLATBED_W_TEAM', label: 'Flatbed w/Team' },
  { value: 'FLATBED_OR_STEP_DECK', label: 'Flatbed or Step Deck' },
  { value: 'FLATBED_VAN_REEFER', label: 'Flatbed/Van/Reefer' },
  { value: 'HOPPER_BOTTOM', label: 'Hopper Bottom' },
  { value: 'LOWBOY', label: 'Lowboy' },
  { value: 'MOVING_VAN', label: 'Moving Van' },
  { value: 'PNEUMATIC', label: 'Pneumatic' },
  { value: 'POWER_ONLY', label: 'Power Only' },
  { value: 'REEFER', label: 'Reefer' },
  { value: 'REEFER_AIR_RIDE', label: 'Reefer, Air-Ride' },
  { value: 'REEFER_DOUBLE', label: 'Reefer, Double' },
  { value: 'REEFER_HAZ_MAT', label: 'Reefer, HazMat' },
  { value: 'REEFER_INTERMODAL', label: 'Reefer, Intermodal' },
  { value: 'REEFER_LOGISTICS', label: 'Reefer, Logistics' },
  { value: 'REEFER_W_TEAM', label: 'Reefer w/Team' },
  { value: 'REMOVABLE_GOOSENECK', label: 'Removable Gooseneck' },
  { value: 'STEP_DECK', label: 'Step Deck' },
  { value: 'STRETCH_TRAILER', label: 'Stretch Trailer' },
  { value: 'TANKER_ALUMINUM', label: 'Tanker, Aluminum' },
  { value: 'TANKER_INTERMODAL', label: 'Tanker, Intermodal' },
  { value: 'TANKER_STEEL', label: 'Tanker, Steel' },
  { value: 'TRUCK_AND_TRAILER', label: 'Truck and Trailer' },
  { value: 'VAN', label: 'Van' },
  { value: 'VAN_AIR_RIDE', label: 'Van, Air-Ride' },
  { value: 'VAN_CONESTOGA', label: 'Van, Conestoga' },
  { value: 'VAN_CURTAIN', label: 'Van, Curtain' },
  { value: 'VAN_DOUBLE', label: 'Van, Double' },
  { value: 'VAN_HAZ_MAT', label: 'Van, HazMat' },
  { value: 'VAN_HOTSHOT', label: 'Van, Hotshot' },
  { value: 'VAN_INSULATED', label: 'Van, Insulated' },
  { value: 'VAN_INTERMODAL', label: 'Van, Intermodal' },
  { value: 'VAN_LIFT_GATE', label: 'Van, Lift-Gate' },
  { value: 'VAN_LOGISTICS', label: 'Van, Logistics' },
  { value: 'VAN_OPEN_TOP', label: 'Van, Open-Top' },
  { value: 'VAN_ROLLER_BED', label: 'Van, Roller Bed' },
  { value: 'VAN_TRIPLE', label: 'Van, Triple' },
  { value: 'VAN_VENTED', label: 'Van, Vented' },
  { value: 'VAN_W_TEAM', label: 'Van w/Team' },
  { value: 'VAN_OR_FLATBED_W_TARPS', label: 'Van or Flatbed w/Tarps' },
  { value: 'VAN_OR_FLATBED', label: 'Van or Flatbed' },
  { value: 'VAN_OR_REEFER', label: 'Van or Reefer' },
  { value: 'INSULATED_VAN_OR_REEFER', label: 'Insulated Van or Reefer' },
  { value: 'REEFER_OR_VENTED_VAN', label: 'Reefer or Vented Van' },
  { value: 'FLATBED_W_CHAINS', label: 'Flatbed, w/Chains' },
  { value: 'REEFER_W_PALLET_EXCHANGE', label: 'Reefer, w/Pallet Exchange' },
  { value: 'VAN_W_BLANKET_WRAP', label: 'Van, w/Blanket Wrap' },
  { value: 'LOWBOY_OR_RGN', label: 'Lowboy or RGN' },
  { value: 'VAN_W_PALLET_EXCHANGE', label: 'Van, w/Pallet Exchange' },
  { value: 'STEP_DECK_OR_RGN', label: 'Step Deck or RGN' },
  { value: 'CONVEYOR', label: 'Conveyor' },
  { value: 'FLATBED_OVER_DIMENSION', label: 'Flatbed, Over Dimension' },
  { value: 'LOWBOY_OVER_DIMENSION', label: 'Lowboy, Over Dimension' },
  { value: 'CONESTOGA', label: 'Conestoga' },
  { value: 'FLATBED_CONESTOGA', label: 'Flatbed Conestoga' },
  { value: 'STEPDECK_CONESTOGA', label: 'Stepdeck Conestoga' },
  { value: 'STRAIGHT_BOX_TRUCK', label: 'Straight Box Truck' },
];

export const indexedTruckOptions = R.indexBy(
  R.prop(FIELD_VALUE),
  truckOptions,
);
