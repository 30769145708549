const idbNameAmousUI = 'Amous-UI';
const idbStoreNameAmousUI = 'settings';

const AmousUIIdbProps = { idbName: idbNameAmousUI, idbStoreName: idbStoreNameAmousUI };

const fleetProfileTabsViewIdbId = 'fleetProfileTabsViewIdbId';
const payrollAddPayrollShowFiltersIdbId = 'payrollAddPayrollShowFiltersIdbId';
const payrollEditPayrollChargesViewIdbId = 'payrollEditPayrollChargesViewIdbId';
const routeTemplateScheduleRouteViewIdbId = 'routeTemplateScheduleRouteViewIdbId';
const masterInvoiceEditMasterInvoiceChargesViewIdbId = 'masterInvoiceEditMasterInvoiceChargesViewIdbId';

const vendorPayrollEditPayrollChargesViewIdbId = 'vendorPayrollEditPayrollChargesViewIdbId';

export {
  AmousUIIdbProps,
  //
  fleetProfileTabsViewIdbId,
  payrollAddPayrollShowFiltersIdbId,
  payrollEditPayrollChargesViewIdbId,
  routeTemplateScheduleRouteViewIdbId,
  vendorPayrollEditPayrollChargesViewIdbId,
  masterInvoiceEditMasterInvoiceChargesViewIdbId,
};
