const PAGE_WRAPPER_MARGIN_TOP = 0;
const PAGE_WRAPPER_PADDING_LEFT = 65;
const PAGE_WRAPPER_PADDING_BOTTOM = 60;

const PAGE_HEADER_WRAPPER_HEIGHT = 60;

const SIDEBAR_CONTAINER_WIDTH = 210;

const QUICK_DAYS_RANGE = [7, 14, 30, 60];
const QUICK_DAYS_FILTER = [7, 14, 30, 180];
const QUICK_DAYS_FILTER_2 = [1, 7, 14, 30, 180];

const COMMON_MUI_TAB_STYLES = { minHeight: 36, padding: '10px', minWidth: 'unset' };

const COMMON_MUI_TABS_STYLES = {
  flexShrink: 0,
  maxHeight: 36,
  minHeight: 36,
  width: 'max-content',
};

export {
  QUICK_DAYS_RANGE,
  QUICK_DAYS_FILTER,
  QUICK_DAYS_FILTER_2,
  COMMON_MUI_TAB_STYLES,
  COMMON_MUI_TABS_STYLES,
  PAGE_WRAPPER_MARGIN_TOP,
  SIDEBAR_CONTAINER_WIDTH,
  PAGE_WRAPPER_PADDING_LEFT,
  PAGE_HEADER_WRAPPER_HEIGHT,
  PAGE_WRAPPER_PADDING_BOTTOM,
};
