export const REF_SCOPE_NAME_CLO = 'CLO';
export const REF_SCOPE_NAME_TEL = 'TEL';
export const REF_SCOPE_NAME_ITEM = 'item';
export const REF_SCOPE_NAME_USER = 'user';
export const REF_SCOPE_NAME_ROUTE = 'route';
export const REF_SCOPE_NAME_BRANCH = 'branch';
export const REF_SCOPE_NAME_CARRIER = 'carrier';
export const REF_SCOPE_NAME_LOCATION = 'location';
export const REF_SCOPE_NAME_LOAD_EVENT = 'load-event';
export const REF_SCOPE_NAME_FLEET_TRUCK = 'fleet-truck';
export const REF_SCOPE_NAME_FLEET_DRIVER = 'fleet-driver';
export const REF_SCOPE_NAME_FLEET_VENDOR = 'fleet-vendor';
export const REF_SCOPE_NAME_FLEET_TRAILER = 'fleet-trailer';
export const REF_SCOPE_NAME_FLEET_INVOICE = 'fleet-invoice';
export const REF_SCOPE_NAME_DRIVER_PAYROLL = 'driver-payroll';
export const REF_SCOPE_NAME_CARRIER_INVOICE = 'carrier-invoice';
export const REF_SCOPE_NAME_CUSTOMER_INVOICE = 'customer-invoice';
export const REF_SCOPE_NAME_SERVICE_VENDOR_INVOICE = 'service-vendor-invoice';

// reference object field names
export const REFERENCE_FIELD_NAME_CLO = 'clo.references';
export const REFERENCE_FIELD_NAME_TEL = 'tel.references';
export const REFERENCE_FIELD_NAME_CLOS = 'clos.references';
export const REFERENCE_FIELD_NAME_TELS = 'tels.references';
export const REFERENCE_FIELD_NAME_LOAD = 'load.references';
export const referenceFieldNameLocaleMap = {
  [REFERENCE_FIELD_NAME_TEL]: ['titles:tel', 'Trip'],
  [REFERENCE_FIELD_NAME_CLO]: ['titles:clo', 'Order'],
  [REFERENCE_FIELD_NAME_LOAD]: ['titles:load', 'Load'],
  [REFERENCE_FIELD_NAME_TELS]: ['titles:tels', 'Trips'],
  [REFERENCE_FIELD_NAME_CLOS]: ['titles:clos', 'Orders'],
};
