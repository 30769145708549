import {
  FIELD_FAX,
  FIELD_ZIP,
  FIELD_CITY,
  FIELD_STATE,
  FIELD_PHONE,
  FIELD_EMAIL,
  FIELD_COUNTRY,
  FIELD_LATITUDE,
  FIELD_CONTACTS,
  FIELD_COMMENTS,
  FIELD_ADDRESS_1,
  FIELD_LONGITUDE,
  FIELD_ADDRESS_2,
  FIELD_CONTACT_NAME,
  FIELD_LOCATION_NAME,
  FIELD_LOCATION_TYPE,
} from './system-fields';
//////////////////////////////////////////////////

export const locationDefaultFields = {
  [FIELD_ZIP]: null,
  [FIELD_CITY]: null,
  [FIELD_STATE]: null,
  [FIELD_COUNTRY]: null,
  [FIELD_LATITUDE]: null,
  [FIELD_CONTACTS]: null,
  [FIELD_COMMENTS]: null,
  [FIELD_ADDRESS_1]: null,
  [FIELD_LONGITUDE]: null,
  [FIELD_ADDRESS_2]: null,
  [FIELD_LOCATION_NAME]: null,
  [FIELD_LOCATION_TYPE]: null,
};

export const contactDefaultFields = {
  [FIELD_FAX]: null,
  [FIELD_PHONE]: null,
  [FIELD_EMAIL]: null,
  [FIELD_CONTACT_NAME]: null,
};
