// constants
import {
  FIELD_ITEM_VOLUME_UOM,
  FIELD_ITEM_WEIGHT_TYPE,
  FIELD_ITEM_TEMPERATURE_UOM,
  FIELD_ITEM_DIMENSIONS_UOM,
  FIELD_TOTAL_TRIP_WEIGHT_UOM,
  FIELD_TOTAL_TRIP_DISTANCE_UOM,
} from './system-fields';
//////////////////////////////////////////////////

// systems
export const METRIC_SYSTEM = 'metric';
export const IMPERIAL_SYSTEM = 'imperial';

// uom
export const UOM_MILE = 'MILE';
export const UOM_INCH = 'INCH';
export const UOM_FOOT = 'FOOT';
export const UOM_METER = 'METER';
export const UOM_KILOMETER = 'KM';
export const UOM_CENTIMETER = 'CENTIMETER';

// distance label
export const UOM_FOOT_LABEL = 'Feet';
export const UOM_MILE_LABEL = 'Miles';
export const UOM_INCH_LABEL = 'Inches';
export const UOM_METER_LABEL = 'Meters';
export const UOM_KILOMETER_LABEL = 'Kilometers';
export const UOM_CENTIMETER_LABEL = 'Centimeters';
// dimension
export const UOM_FEET = 'Feet';
export const UOM_INCHES = 'Inches';
export const UOM_METERS = 'Meters';
export const UOM_CENTIMETERS = 'Centimeters';
// weight
export const UOM_POUND = 'LB';
export const UOM_KILOGRAM = 'KG';
export const UOM_TON_US = 'TON_US';
export const UOM_POUND_100 = 'LB100';
export const UOM_KILOGRAM_100 = 'KG100';
export const UOM_METRIC_TON = 'METRIC_TON';
// volume
export const UOM_LITER = 'LITER';
export const UOM_CUBIC_M = 'CUBIC_M';
export const UOM_CUBIC_FT = 'CUBIC_FT';
export const UOM_GALLON_LIQUID = 'GALLON_LIQUID';
// temperature
export const UOM_FAHRENHEIT = 'Fahrenheit';
export const UOM_CELSIUS = 'Celsius';
// cube
export const UOM_CUBIC_FEET = 'cubic ft';
export const UOM_CUBIC_METERS = 'cubic m';
// interval
export const UOM_DAYS = 'DAYS';
export const UOM_HOURS = 'HOURS';
export const UOM_MILES = 'MILES';
export const UOM_KILOMETERS = 'KILOMETERS';

export const defaultImperialItemUoms = {
  [FIELD_ITEM_WEIGHT_TYPE]: UOM_POUND,
  [FIELD_ITEM_VOLUME_UOM]: UOM_CUBIC_FT,
  [FIELD_ITEM_DIMENSIONS_UOM]: UOM_INCHES,
  [FIELD_ITEM_TEMPERATURE_UOM]: UOM_FAHRENHEIT,
};

export const defaultMetricItemUoms = {
  [FIELD_ITEM_VOLUME_UOM]: UOM_CUBIC_M,
  [FIELD_ITEM_WEIGHT_TYPE]: UOM_KILOGRAM,
  [FIELD_ITEM_TEMPERATURE_UOM]: UOM_CELSIUS,
  [FIELD_ITEM_DIMENSIONS_UOM]: UOM_CENTIMETERS,
};

export const uomSystemToDistanceUomMap = {
  [IMPERIAL_SYSTEM]: UOM_MILE,
  [METRIC_SYSTEM]: UOM_KILOMETER,
};

export const defaultItemUoms = {
  [METRIC_SYSTEM]: defaultMetricItemUoms,
  [IMPERIAL_SYSTEM]: defaultImperialItemUoms,
};

export const defaultPricingModeFields = {
  [IMPERIAL_SYSTEM]: {
    [FIELD_TOTAL_TRIP_WEIGHT_UOM]: UOM_POUND,
    [FIELD_TOTAL_TRIP_DISTANCE_UOM]: UOM_MILE,
  },
  [METRIC_SYSTEM]: {
    [FIELD_TOTAL_TRIP_WEIGHT_UOM]: UOM_KILOGRAM,
    [FIELD_TOTAL_TRIP_DISTANCE_UOM]: UOM_KILOMETER,
  },
};

export const defaultSystemUoms = {
  [IMPERIAL_SYSTEM]: {
    [FIELD_ITEM_WEIGHT_TYPE]: UOM_POUND,
    [FIELD_ITEM_DIMENSIONS_UOM]: UOM_INCHES,
    [FIELD_ITEM_VOLUME_UOM]: UOM_CUBIC_FEET,
    [FIELD_TOTAL_TRIP_WEIGHT_UOM]: UOM_POUND,
    [FIELD_ITEM_TEMPERATURE_UOM]: UOM_FAHRENHEIT,
    [FIELD_TOTAL_TRIP_DISTANCE_UOM]: UOM_MILE,
  },
  [METRIC_SYSTEM]: {
    [FIELD_ITEM_TEMPERATURE_UOM]: UOM_CELSIUS,
    [FIELD_ITEM_WEIGHT_TYPE]: UOM_KILOGRAM,
    [FIELD_ITEM_DIMENSIONS_UOM]: UOM_CENTIMETERS,
    [FIELD_ITEM_VOLUME_UOM]: UOM_CUBIC_METERS,
    [FIELD_TOTAL_TRIP_WEIGHT_UOM]: UOM_KILOGRAM,
    [FIELD_TOTAL_TRIP_DISTANCE_UOM]: UOM_KILOMETER,
  },
};

export const uomLocaleMap = {
  [UOM_MILE]: {
    plural: 'titles:miles',
    singular: 'titles:mile',
  },
  [UOM_KILOMETER]: {
    plural: 'titles:kilometers',
    singular: 'titles:kilometer',
  },
  [UOM_KILOGRAM]: {
    plural: 'uoms:uom-kilogram-plural',
    singular: 'uoms:uom-kilogram-singular',
  },
  [UOM_POUND]: {
    plural: 'uoms:uom-pound-plural',
    singular: 'uoms:uom-pound-singular',
  },
  [UOM_METRIC_TON]: {
    plural: 'uoms:uom-metric-ton-plural',
    singular: 'uoms:uom-metric-ton-singular',
  },
  [UOM_TON_US]: {
    plural: 'uoms:uom-ton-us-plural',
    singular: 'uoms:uom-ton-us-singular',
  },
  [UOM_FOOT]: {
    plural: 'titles:feet',
  },
  [UOM_INCH]: {
    plural: 'titles:inches',
  },
  [UOM_METER]: {
    plural: 'titles:meters',
  },
  [UOM_CENTIMETER]: {
    plural: 'titles:centimeters',
  },
  [UOM_DAYS]: {
    plural: 'titles:days',
  },
  [UOM_HOURS]: {
    plural: 'titles:hours',
  },
  [UOM_MILES]: {
    plural: 'titles:miles',
  },
  [UOM_KILOMETERS]: {
    plural: 'titles:kilometers',
  },
  [UOM_POUND_100]: {
    plural: 'titles:pounds100',
  },
  [UOM_KILOGRAM_100]: {
    plural: 'titles:kg100',
  },
  [UOM_CUBIC_M]: {
    plural: 'titles:cubic-metres',
  },
  [UOM_CUBIC_FT]: {
    plural: 'titles:cubic-feet',
  },
  [UOM_GALLON_LIQUID]: {
    plural: 'titles:gallons-liquid',
  },
};
