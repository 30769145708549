const ACIEntryPort = {
  ABBOTSFORD_HUNTINGDON: 'Abbotsford Huntingdon (0817)',
  ABERCORN: 'Abercorn (0318)',
  ADEN: 'Aden (0706)',
  ALDERGROVE: 'Aldergrove (0841)',
  ANDOVER: 'Andover (0214)',
  ARMSTRONG: 'Armstrong (0329)',
  BEAVER_CREEK: 'Beaver Creek (0892)',
  BIG_BEAVIER: 'Big Beavier (0614)',
  BOISSEVAIN: 'Boissevain (0507)',
  BOUNDARY_BAY: 'Boundary Bay (0815)',
  CAMPOBELLO: 'Campobello (0225)',
  CARIEVALE: 'Carievale (0612)',
  CARSON: 'Carson (0834)',
  CARTWRIGHT: 'Cartwright (0521)',
  CARWAY: 'Carway (0707)',
  CASCADE: 'Cascade (0816)',
  CENTREVILLE: 'Centreville (0215)',
  CHARTIERVILLE: 'Chartierville (0365)',
  CHIEF_MOUNTAIN: 'Chief Mountain (0709)',
  CHOPAKA: 'Chopaka (0836)',
  CLAIR: 'Clair (0216)',
  CLARENCEVILLE: 'Clarenceville (0337)',
  CLIMAX: 'Climax (0619)',
  CORNWALL: 'Cornwall (0409)',
  CORONACH: 'Coronach (0615)',
  COULTER: 'Coulter (0524)',
  COUTTS: 'Coutts (0705)',
  COVEY_HILL: 'Covey Hill (0311)',
  CRYSTAL_CITY: 'Crystal City (0520)',
  DEL_BONITA: 'Del Bonita (0708)',
  DUNDEE: 'Dundee (0330)',
  EAST_HEREFOED: 'East Herefoed (0362)',
  EAST_PINNACLE: 'East Pinnacle (0369)',
  EDMUNSTON: 'Edmunston (0213)',
  EMERSON: 'Emerson (0502)',
  ESTEVAN_HIGHWAY: 'Estevan Highway (0610)',
  FORT_EREI: 'Fort Erei (0410)',
  FORT_FRANCES_BRIDGE: 'Fort Frances Bridge (0478)',
  FRASER: 'Fraser (0893)',
  FRELIGHSBURG: 'Frelighsburg (0332)',
  GILLESPIE_PORTAGE: 'Gillespie Portage (0219)',
  GLEN_SUTTON: 'Glen Sutton (0370)',
  GOODLANDS: 'Goodlands (0508)',
  GRAND_FALLS: 'Grand Falls (0217)',
  GRETNA: 'Gretna (0503)',
  HEMMINGFORD: 'Hemmingford (0333)',
  HERDMAN: 'Herdman (0302)',
  HEREFORD_ROAD: 'Hereford Road (0366)',
  HIGHWATER: 'Highwater (0334)',
  KINGSGATE: 'Kingsgate (0818)',
  KINGSTON: 'Kingston (0420)',
  LACOLLE_ST_BERNARD_DE_LACOLLE: 'Lacolle St Bernard De Lacolle (0351)',
  LANSDOWNE_THOUSAND_ISLANDS_BRIDGE: 'Lansdowne Thousand Islands Bridge (0456)',
  LENA: 'Lena (0522)',
  LITTLE_GOLD_CREEK: 'Little Gold Creek (0895)',
  LYLETON: 'Lyleton (0523)',
  MIDWAY: 'Midway (0835)',
  MONCHY: 'Monchy (0620)',
  MORSES_LINE: 'Morses Line (0367)',
  NELWAY: 'Nelway (0828)',
  NIAGARA_FALLS_QUEENSTON_LEWISTON_BRIDGE: 'Niagara Falls Queenston Lewiston Bridge (0427)',
  NORTHGATE: 'Northgate (0613)',
  NORTH_PORTAL: 'North Portal (0602)',
  NOYAN: 'Noyan (0368)',
  OSOYOOS: 'Osoyoos (0819)',
  OUNGRE: 'Oungre (0616)',
  PACIFIC_HIGHWAY: 'Pacific Highway (0813)',
  PATERSON: 'Paterson (0832)',
  PIGEON_RIVER: 'Pigeon River (0475)',
  PINEY: 'Piney (0517)',
  PLEASANT_CAMP: 'Pleasant Camp (0891)',
  POHENEGAMOOK: 'Pohenegamook (0331)',
  PRESCOTT: 'Prescott (0439)',
  RAINY_RIVER: 'Rainy River (0488)',
  REGWAY: 'Regway (0607)',
  ROOSVILLE: 'Roosville (0824)',
  ROUTE_221_LACOLLE: 'Route 221 Lacolle (0324)',
  ROUTE_223_LACOLLE: 'Route 223 Lacolle (0341)',
  RYKERTS: 'Rykerts (0822)',
  SARNIA: 'Sarnia (0440)',
  SAULT_STE_MARIE: 'Sault Ste Marie (0441)',
  SNOWFLAKE: 'Snowflake (0509)',
  SOUTH_JUNCTION: 'South Junction (0506)',
  SPRAGUE: 'Sprague (0505)',
  STANHOPE: 'Stanhope (0354)',
  STANSTEAD_55: 'Stanstead 55 (0314)',
  STANSTEAD_BEEBE: 'Stanstead Beebe (0376)',
  STANSTEAD_RTE_143: 'Stanstead Rte 143 (0375)',
  ST_ARMAND_PHILIPSBURG: 'St Armand Philipsburg (0328)',
  ST_CROIX: 'St Croix (0205)',
  STE_AURELIE: 'Ste Aurelie (0339)',
  ST_JUST_DE_BRETENIERES: 'St Just De Bretenieres (0336)',
  ST_LEONARD: 'St Leonard (0218)',
  ST_PAMPHILE: 'St Pamphile (0335)',
  ST_STEPHEN_3RD_BRIDGE: 'St Stephen 3rd Bridge (0231)',
  TOLSTOI: 'Tolstoi (0516)',
  TORQUAY: 'Torquay (0617)',
  TROUT_RIVER: 'Trout River (0307)',
  VICTORIA: 'Victoria (0811)',
  WANETA: 'Waneta (0833)',
  WEST_POPLAR_RIVER: 'West Poplar River (0618)',
  WILD_HORSE: 'Wild Horse (0711)',
  WILLOW_CREEK: 'Willow Creek (0621)',
  WINDSOR_AMBASSADOR_BRIDGE: 'Windsor Ambassador Bridge (0453)',
  WINDSOR_TUNNEL: 'Windsor Tunnel (0452)',
  WINDYGATES: 'Windygates (0519)',
  WINKLER: 'Winkler (0518)',
  WOBURN: 'Woburn (0308)',
  WOODSTOCK: 'Woodstock (0212)',
  YARMOUTH: 'Yarmouth (0025)',
};

const ACIReleaseOffice = {
  ABBOTSFORD_HUNTINGDON: 'Abbotsford Huntingdon (0817)',
  ABERCORN: 'Abercorn (0318)',
  ADEN: 'Aden (0706)',
  ALDERGROVE: 'Aldergrove (0841)',
  ANDOVER: 'Andover (0214)',
  ARGENTIA: 'Argentia (0921)',
  ARMSTRONG: 'Armstrong (0329)',
  ARNPRIOR: 'Arnprior (0494)',
  BAIE_COMEAU: 'Baie Comeau (0355)',
  BARRIE: 'Barrie (0459)',
  BATHURST: 'Bathurst (0201)',
  BEAVER_CREEK: 'Beaver creek (0892)',
  BELLEVILLE: 'Belleville (0402)',
  BIG_BEAVER: 'Big beaver (0614)',
  BOISSEVAIN: 'Boissevain (0507)',
  BOUNDARY_BAY: 'Boundary bay (0815)',
  BRACEBRIDGE: 'Bracebridge (0460)',
  BRAMPTON: 'Brampton (0480)',
  BRANTFORD: 'Brantford (0404)',
  BROCKVILLE: 'Brockville (0405)',
  CALGARY: 'Calgary (0701)',
  CAMBRIDGE: 'Cambridge (0457)',
  CAMPBELL_RIVER: 'Campbell river (0838)',
  CAMPOBELLO: 'Campobello (0225)',
  CAP_AUX_MEULES: 'Cap Aux Meules (0363)',
  CARAQUET: 'Caraquet (0221)',
  CARIEVALE: 'Carievale (0612)',
  CARSON: 'Carson (0834)',
  CARTWRIGHT: 'Cartwright (0521)',
  CARWAY: 'Carway (0707)',
  CASCADE: 'Cascade (0816)',
  CENTREVILLE: 'Centreville (0215)',
  CHARLOTTETOWN: 'Charlottetown (0101)',
  CHARTIERVILLE: 'Chartierville (0365)',
  CHATHAM: 'Chatham (0406)',
  CHICOUTIMI: 'Chicoutimi (0301)',
  CHIEF_MOUNTAIN: 'Chief mountain (0709)',
  CHOPAKA: 'Chopaka (0836)',
  CHURCHILL: 'Churchill (0511)',
  CLAIR: 'Clair (0216)',
  CLARENCEVILLE: 'Clarenceville (0337)',
  CLARENVILLE: 'Clarenville (0900)',
  CLIMAX: 'Climax (0619)',
  COBOURG: 'Cobourg (0473)',
  COLLINGWOOD: 'Collingwood (0458)',
  CORNER_BROOK: 'Corner brook (0911)',
  CORNWALL: 'Cornwall (0409)',
  CORONACH: 'Coronach (0615)',
  COULTER: 'Coulter (0524)',
  COURTENAY: 'Courtenay (0830)',
  COUTTS: 'Coutts (0705)',
  COVEY_HILL: 'Covey hill (0311)',
  COWANSVILLE: 'Cowansville (0356)',
  CRANBROOK: 'Cranbrook (0801)',
  CRYSTAL_CITY: 'Crystal city (0520)',
  DAAQUAM: 'Daaquam (0371)',
  DALHOUSIE: 'Dalhousie (0202)',
  DAWSON_CITY: 'Dawson city (0894)',
  DAWSON_CREEK: 'Dawson creek (0839)',
  DEER_ISLAND_POINT: 'Deer island point (0208)',
  DEL_BONITA: 'Del bonita (0708)',
  DOUGLAS: 'Douglas (0840)',
  DRUMMONDVILLE: 'Drummondville (0303)',
  DUNDEE: 'Dundee (0330)',
  EAST_HEREFORD: 'East hereford (0362)',
  EAST_PINNACLE: 'East pinnacle (0369)',
  EDMONTON: 'Edmonton (0702)',
  EDMUNSTON: 'Edmunston (0213)',
  EMERSON: 'Emerson (0502)',
  ESTEVAN_HIGHWAY: 'Estevan highway (0610)',
  FORT_ERIE: 'Fort erie (0410)',
  FORT_FRANCES_BRIDGE: 'Fort frances bridge (0478)',
  FORTUNE: 'Fortune (0919)',
  FRASER: 'Fraser (0893)',
  FREDERICTON: 'Fredericton (0204)',
  FRELIGHSBURG: 'Frelighsburg (0332)',
  GANDER: 'Gander (0912)',
  GASPE: 'Gaspe (0304)',
  GENERIC: 'Generic (1000)',
  GILLESPIE_PORTAGE: 'Gillespie portage (0219)',
  GLEN_SUTTON: 'Glen sutton (0370)',
  GOODLANDS: 'Goodlands (0508)',
  GOOSE_BAY: 'Goose bay (0913)',
  GRANBY: 'Granby (0305)',
  GRAND_FALLS: 'Grand falls (0217)',
  GRAND_FALLS_NEWFOUNDLAND: 'Grand falls, newfoundland (0910)',
  GRAND_MANAN: 'Grand manan (0224)',
  GRETNA: 'Gretna (0503)',
  GUELPH: 'Guelph (0414)',
  HALIFAX: 'Halifax (0009)',
  HALIFAX_INTERNATIONAL_AIRPORT: 'Halifax   international airport (0026)',
  HALIFAX_POSTAL_OPERATIONS: 'Halifax   postal operations (0007)',
  HALTON_HILLS: 'Halton hills (0483)',
  HAMILTON: 'Hamilton (0417)',
  HANOVER: 'Hanover (0448)',
  HARBOUR_GRACE: 'Harbour grace (0922)',
  HEMMINGFORD: 'Hemmingford (0333)',
  HERDMAN: 'Herdman (0302)',
  HEREFORD_ROAD: 'Hereford road (0366)',
  HIGHWATER: 'Highwater (0334)',
  INTERNATIONAL_MAIL_PROCESSING_CENTER: 'International mail processing center (0491)',
  INUVIK: 'Inuvik (0512)',
  IQALUIT: 'Iqaluit (0403)',
  JOLIETTE: 'Joliette (0342)',
  KAMLOOPS: 'Kamloops (0814)',
  KELOWNA: 'Kelowna (0831)',
  KENORA: 'Kenora (0490)',
  KENTVILLE: 'Kentville (0010)',
  KINGSGATE: 'Kingsgate (0818)',
  KINGSTON: 'Kingston (0420)',
  KITCHENER: 'Kitchener (0401)',
  KITIMAT: 'Kitimat (0827)',
  LACHUTE: 'Lachute (0343)',
  LACOLLE_ST_BERNARD_DE_LACOLLE: 'Lacolle   st bernard de lacolle (0351)',
  LANSDOWNE_THOUSAND_ISLANDS_BRIDGE: 'Lansdowne   thousand islands bridge (0456)',
  LENA: 'Lena (0522)',
  LETHBRIDGE: 'Lethbridge (0703)',
  LINDSAY: 'Lindsay (0477)',
  LITTLE_GOLD_CREEK: 'Little gold creek (0895)',
  LIVERPOOL: 'Liverpool (0011)',
  LONDON: 'London (0423)',
  LUNENBURG: 'Lunenburg (0013)',
  LYLETON: 'Lyleton (0523)',
  MIDLAND: 'Midland (0424)',
  MIDWAY: 'Midway (0835)',
  MIRAMICHI: 'Miramichi (0207)',
  MONCHY: 'Monchy (0620)',
  MONCTON: 'Moncton (0206)',
  MONTMAGNY: 'Montmagny (0344)',
  MONTREAL_INTERMEDIATE_TERMINAL: 'Montreal   intermediate terminal (c.d.l.) (0398)',
  MONTREAL_INTL_AIRPORT_DORVAL: 'Montreal   international airport (dorval) (0396)',
  MONTREAL_INTL_AIRPORT_MIRABEL: 'Montreal   international airport (mirabel) (0399)',
  MONTREAL_MAIN_LONGROOM: 'Montreal   main longroom (0395)',
  MOOSE_JAW: 'Moose jaw (0601)',
  MORSES_LINE: 'Morses line (0367)',
  NANAIMO: 'Nanaimo (0804)',
  NELWAY: 'Nelway (0828)',
  NEW_GLASGOW: 'New glasgow (0015)',
  NEWMARKET: 'Newmarket (0486)',
  NIAGARA_FALLS_QUEENSTON_LEWISTON_BRIDGE: 'Niagara falls   queenston lewiston bridge (0427)',
  NORTH_BAY: 'North bay (0428)',
  NORTHGATE: 'Northgate (0613)',
  NORTH_PORTAL: 'North portal (0602)',
  NOYAN: 'Noyan (0368)',
  OAKVILLE: 'Oakville (0476)',
  ORANGEVILLE: 'Orangeville (0487)',
  ORILLIA: 'Orillia (0429)',
  OSHAWA: 'Oshawa (0430)',
  OSOYOOS: 'Osoyoos (0819)',
  OTTAWA_LONGROOM_SUFFERANCE: 'Ottawa   longroom/sufferance (0431)',
  OTTAWA_MACDONALD_CARTIER_INTL_AIRPORT: 'Ottawa macdonald   cartier international airport (0485)',
  OUNGRE: 'Oungre (0616)',
  OWEN_SOUND: 'Owen sound (0482)',
  PACIFIC_CUSTOMS_BROKERS_HIGHWAY_SUFFERANCE_WAREHOUSE: 'Pacific customs brokers highway sufferance warehouse (0842)',
  PACIFIC_HIGHWAY: 'Pacific highway (0813)',
  PARRY_SOUND_GEORGIAN_BAY_AIRPORT: 'Parry sound   georgian bay airport (0435)',
  PATERSON: 'Paterson (0832)',
  PEMBROKE: 'Pembroke (0469)',
  PENTICTON: 'Penticton (0807)',
  PERTH: 'Perth (0470)',
  PETERBOROUGH: 'Peterborough (0400)',
  PIGEON_RIVER: 'Pigeon river (0475)',
  PINEY: 'Piney (0517)',
  PLEASANT_CAMP: 'Pleasant camp (0891)',
  POHENEGAMOOK: 'Pohenegamook (0331)',
  PORT_ALBERNI: 'Port alberni (0825)',
  PORT_CARTIER: 'Port Cartier (0338)',
  PORT_COLBORNE: 'Port colborne (0471)',
  PORT_HAWKESBURY: 'Port hawkesbury (0019)',
  POWELL_RIVER: 'Powell river (0826)',
  PRESCOTT: 'Prescott (0439)',
  PRINCE_ALBERT: 'Prince albert (0603)',
  PRINCE_GEORGE: 'Prince george (0820)',
  PRINCE_RUPERT: 'Prince rupert (0808)',
  QUEBEC: 'Quebec (0312)',
  RAINY_RIVER: 'Rainy river (0488)',
  REGINA: 'Regina (0604)',
  REGWAY: 'Regway (0607)',
  RICHMOND: 'Richmond (0345)',
  RIMOUSKI: 'Rimouski (0313)',
  RIVIERE_DU_LOUP: 'Riviere Du Loup (0340)',
  ROOSVILLE: 'Roosville (0824)',
  ROUTE_221_LACOLLE: 'Route 221: lacolle (0324)',
  ROUTE_223_LACOLLE: 'Route 223: lacolle (0341)',
  ROUYN_NORANDA: 'Rouyn Noranda (0349)',
  RYKERTS: 'Rykerts (0822)',
  SAINT_JOHN_NEW_BRUNSWICK: 'Saint john, new brunswick (0210)',
  SARNIA: 'Sarnia (0440)',
  SASKATOON: 'Saskatoon (0605)',
  SAULT_STE_MARIE: 'Sault ste. marie (0441)',
  SEPT_ILES: 'Sept Iles (0361)',
  SHAWINIGAN: 'Shawinigan (0315)',
  SHELBURNE: 'Shelburne (0020)',
  SHERBROOKE: 'Sherbrooke (0316)',
  SIMCOE: 'Simcoe (0442)',
  SMITHS_FALLS: 'Smiths falls (0474)',
  SNOWFLAKE: 'Snowflake (0509)',
  SOMBRA: 'Sombra (0465)',
  SOREL: 'Sorel (0317)',
  SOUTH_JUNCTION: 'South junction (0506)',
  SPRAGUE: 'Sprague (0505)',
  ST_ANDREWS: 'St. andrews (0209)',
  STANHOPE: 'Stanhope (0354)',
  STANSTEAD_55: 'Stanstead 55 (0314)',
  STANSTEAD_BEEBE: 'Stanstead beebe (0376)',
  STANSTEAD_RTE_143: 'Stanstead rte 143 (0375)',
  ST_ARMAND_PHILIPSBURG: 'St armand/philipsburg (0328)',
  ST_CATHARINES: 'St. catharines (0445)',
  ST_CROIX: 'St. croix (0205)',
  STE_AURELIE: 'Ste aurelie (0339)',
  STEPHENVILLE: 'Stephenville (0918)',
  STEWART: 'Stewart (0896)',
  ST_HYACINTHE: 'St Hyacinthe (0320)',
  ST_JEAN: 'St Jean (0321)',
  ST_JEROME: 'St Jerome (0346)',
  ST_JOHNS_NEWFOUNDLAND: 'St. johns, newfoundland (0914)',
  ST_JUST_DE_BRETENIERES: 'St Just de bretenieres (0336)',
  ST_LEONARD: 'St. leonard (0218)',
  ST_PAMPHILE: 'St pamphile (0335)',
  STRATFORD: 'Stratford (0425)',
  ST_STEPHEN_3RD_BRIDGE: 'St. stephen 3rd bridge (0231)',
  ST_STEPHEN_FERRY_POINT_BRIDGE: 'St. stephen ferry point bridge (0211)',
  ST_THOMAS: 'St. thomas (0446)',
  SUDBURY: 'Sudbury (0444)',
  SUMMERSIDE: 'Summerside (0102)',
  SYDNEY: 'Sydney (0021)',
  SYDNEY_2: 'Sydney (0837)',
  THETFORD_MINES: 'Thetford mines (0347)',
  THUNDER_BAY: 'Thunder bay (0461)',
  TILLSONBURG: 'Tillsonburg (0447)',
  TIMMINS: 'Timmins (0467)',
  TOLSTOI: 'Tolstoi (0516)',
  TORONTO_INTERPORT_SUFFERANCE_WAREHOUSE: 'Toronto   interport sufferance warehouse (dixie) (0496)',
  TORONTO_METRO_OPERATIONS_DIVISION: 'Toronto   metro operations division (0495)',
  TORONTO_PEARSON_INTERNATIONAL_AIRPORT: 'Toronto   pearson international airport (0497)',
  TORONTO_SUFFERANCE_TRUCK_TERMINAL: 'Toronto   sufferance truck terminal (kennedy road) (0499)',
  TORQUAY: 'Torquay (0617)',
  TRENTON: 'Trenton (0449)',
  TROIS_RIVIERES: 'Trois Rivieres (0322)',
  TROUT_RIVER: 'Trout river (0307)',
  TRURO: 'Truro (0022)',
  TUKTOYAKTUK: 'Tuktoyaktuk (0514)',
  UNKNOWN: 'Unknown (0976)',
  VAL_D_OR: 'Val D Or (0350)',
  VALLEYFIELD: 'Valleyfield (0323)',
  VANCOUVER_COMMERCIAL_OPERATIONS_EAST: 'Vancouver   commercial operations east (0810)',
  VANCOUVER_COMMERCIAL_OPERATIONS_WEST: 'Vancouver   commercial operations west (0809)',
  VANCOUVER_INTERNATIONAL_AIRPORT: 'Vancouver   international airport (0821)',
  VANCOUVER_INTERNATIONAL_MARINE_OPERATIONS: 'Vancouver   international marine operations (0806)',
  VANCOUVER_MAIL_CENTRE: 'Vancouver   mail centre (0803)',
  VERNON: 'Vernon (0823)',
  VICTORIA: 'Victoria (0811)',
  VICTORIAVILLE: 'Victoriaville (0327)',
  WALLACEBURG: 'Wallaceburg (0450)',
  WANETA: 'Waneta (0833)',
  WEST_POPLAR_RIVER: 'West poplar river (0618)',
  WHITEHORSE: 'Whitehorse (0890)',
  WILD_HORSE: 'Wild horse (0711)',
  WILLOW_CREEK: 'Willow creek (0621)',
  WINDSOR_AMBASSADOR_BRIDGE: 'Windsor ambassador bridge (0453)',
  WINDSOR_METRO_OPERATIONS: 'Windsor metro operations (0454)',
  WINDSOR_TUNNEL: 'Windsor tunnel (0452)',
  WINDYGATES: 'Windygates (0519)',
  WINKLER: 'Winkler (0518)',
  WINNIPEG_INTERNATIONAL_AIRPORT: 'Winnipeg   international airport (0510)',
  WINNIPEG_MAIN_LONGROOM: 'Winnipeg   main longroom (0504)',
  WOBURN: 'Woburn (0308)',
  WOODSTOCK_1: 'Woodstock (0212)',
  WOODSTOCK_2: 'Woodstock (0492)',
  YARMOUTH: 'Yarmouth (0025)',
  YELLOWKNIFE: 'Yellowknife (0515)',
};

const ACIShipmentType = {
  PARS: 'Pars (PARS)',
  CSA: 'Csa (CSA)',
  IN_BOND: 'In Bond (BOND)',
  E29B_TEMPORARY_ADMISSION_PERMIT: 'E29B Temporary Admission Permit (E29B)',
  ATA_CARNET: 'Ata Carnet (ATA)',
  ORDERS_IN_COUNCIL: 'Orders In Council (OIC)',
  PERSONAL_GOODS: 'Personal Goods (PG)',
  A49_AUTOMOTIVE_RELEASE: 'A49 Automotive Release (A49)',
  PAPER_RMD: 'Paper Rmd (PAPER_RMD)',
  PAPER_B3: 'Paper B3 (PAPER_B3)',
  GOODS_ASTRAY: 'Goods Astray (ASTRAY)',
  MILITARY_GOODS: 'Military Goods (MILITARY)',
  VALUE_INCLUDED: 'Value Included (VI)',
  MASTER_PROVISIONAL: 'Master Provisional (MP)',
  AUTOMOTIVE_LINE_RELEASE: 'Automotive Line Release (ALR)',
};

const ACEPortOfEntry = {
  PORTLAND_ME: 'Portland, ME (0101)',
  BANGOR_ME: 'Bangor, ME (0102)',
  EASTPORT_ME: 'Eastport, ME (0103)',
  JACKMAN_ME: 'Jackman, ME (0104)',
  VANCEBORO_ME: 'Vanceboro, ME (0105)',
  HOULTON_ME: 'Houlton, ME (0106)',
  FORT_FAIRFIELD_ME: 'Fort Fairfield, ME (0107)',
  VAN_BUREN_ME: 'Van Buren, ME (0108)',
  MADAWASKA_ME: 'Madawaska, ME (0109)',
  FORT_KENT_ME: 'Fort Kent, ME (0110)',
  BATH_ME: 'Bath, ME (0111)',
  BAR_HARBOR_ME: 'Bar Harbor, ME (0112)',
  CALAIS_ME: 'Calais, ME (0115)',
  LIMESTONE_ME: 'Limestone, ME (0118)',
  ROCKLAND_ME: 'Rockland, ME (0121)',
  JONESPORT_ME: 'Jonesport, ME (0122)',
  BRIDGEWATER_ME: 'Bridgewater, ME (0127)',
  PORTSMOUTH_NH: 'Portsmouth, NH (0131)',
  BELFAST_ME: 'Belfast, ME (0132)',
  SEARSPORT_ME: 'Searsport, ME (0152)',
  MANCHESTER_NH: 'Manchester, NH (0182)',
  ST_ALBANS_VT: 'St. Albans, VT (0201)',
  RICHFORD_VT: 'Richford, VT (0203)',
  BEECHER_FALLS_VT: 'Beecher Falls, VT (0206)',
  BURLINGTON_INTL_AIRPORT_VT: 'Burlington International Airport (0207)',
  DERBY_LINE_VT: 'Derby Line, VT (0209)',
  NORTON_VT: 'Norton, VT (0211)',
  HIGHGATE_SPRINGS_VT: 'Highgate Springs, VT (0212)',
  SWANTON_VT: 'Swanton, VT (0214)',
  BOSTON_MA: 'Boston, MA (0401)',
  SPRINGFIELD_MA: 'Springfield, MA (0402)',
  WORCESTER_MA: 'Worcester, MA (0403)',
  GLOUCESTER_MA: 'Gloucester, MA (0404)',
  NEW_BEDFORD_MA: 'New Bedford, MA (0405)',
  PLYMOUTH_MA: 'Plymouth, MA (0406)',
  FALL_RIVER_MA: 'Fall River, MA (0407)',
  SALEM_MA: 'Salem, MA (0408)',
  BRIDGEPORT_CT: 'Bridgeport, CT (0410)',
  HARTFORD_CT: 'Hartford, CT (0411)',
  NEW_HAVEN_CT: 'New Haven, CT (0412)',
  NEW_LONDON_CT: 'New London, CT (0413)',
  LAWRENCE_MA: 'Lawrence, MA (0416)',
  LOGAN_AIRPORT_MA: 'Logan Airport (0417)',
  HANSCOM_USER_FEE_AIRPORT_MA: 'Hanscom User Fee Airport, Bedford, MA (0481)',
  NEWPORT_RI: 'Newport, RI (0501)',
  PROVIDENCE_RI: 'Providence, RI (0502)',
  OGDENSBURG_NY: 'Ogdensburg, NY (0701)',
  MASSENA_NY: 'Massena, NY (0704)',
  CAPE_VINCENT_NY: 'Cape Vincent, NY (0706)',
  ALEXANDRIA_BAY_NY: 'Alexandria Bay, NY (0708)',
  CHAMPLAIN_NY: 'Champlain, NY (0712)',
  TROUT_RIVER_NY: 'Trout River, NY (0715)',
  BUFFALO_NY: 'Buffalo, NY (0901)',
  ROCHESTER_NY: 'Rochester, NY (0903)',
  OSWEGO_NY: 'Oswego, NY (0904)',
  SODUS_POINT_NY: 'Sodus Point, NY (0905)',
  SYRACUSE_NY: 'Syracuse, NY (0906)',
  UTICA_NY: 'Utica, NY (0907)',
  BINGHAMTON_NY: 'Binghamton, NY (0981)',
  ROME_NY: 'Rome, NY (0982)',
  NEW_YORK_NY: 'New York, NY (1001)',
  ALBANY_NY: 'Albany, NY (1002)',
  PHILADELPHIA_PA: 'Philadelphia, PA (1101)',
  PITTSBURGH_PA: 'Pittsburgh, PA (1102)',
  HARRISBURG_PA: 'Harrisburg, PA (1103)',
  SCRANTON_PA: 'Scranton, PA (1104)',
  ERIE_PA: 'Erie, PA (1105)',
  WILLIAMSPORT_PA: 'Williamsport, PA (1106)',
  WILMINGTON_DE: 'Wilmington, DE (1107)',
  ALLIANCE_AIRPORT_PA: 'Alliance Airport, Ft. Worth, TX (1182)',
  NEWARK_NJ: 'Newark, NJ (1109)',
  TRENTON_NJ: 'Trenton, NJ (1110)',
  CAMDEN_NJ: 'Camden, NJ (1111)',
  ATLANTIC_CITY_NJ: 'Atlantic City, NJ (1112)',
  LEBANON_PA: 'Lebanon, PA (1181)',
  WILKES_BARRE_PA: 'Wilkes Barre, PA (1183)',
  CHESTER_PA: 'Chester, PA (1184)',
  CAPE_MAY_NJ: 'Cape May, NJ (1185)',
  BALTIMORE_MD: 'Baltimore, MD (1301)',
  SALISBURY_MD: 'Salisbury, MD (1302)',
  GREENBELT_MD: 'Greenbelt, MD (1303)',
  WASHINGTON_DC: 'Washington, DC (1304)',
  DOVER_DE: 'Dover, DE (1305)',
  CAMBRIDGE_MD: 'Cambridge, MD (1306)',
  PORTSMOUTH_VA: 'Portsmouth, VA (1401)',
  NORFOLK_VA: 'Norfolk, VA (1402)',
  NEWPORT_NEWS_VA: 'Newport News, VA (1406)',
  RICHMOND_VA: 'Richmond, VA (1407)',
  ROANOKE_VA: 'Roanoke, VA (1408)',
  ALEXANDRIA_VA: 'Alexandria, VA (1410)',
  WASHINGTON_DULLES_INTL_AIRPORT_VA: 'Washington-Dulles International Airport (1412)',
  BALTIMORE_USER_FEE_AIRPORT_MD: 'Baltimore User Fee Airport, MD (1481)',
  WILMINGTON_USER_FEE_AIRPORT_DE: 'Wilmington User Fee Airport, DE (1482)',
  MORGANTOWN_USER_FEE_AIRPORT_WV: 'Morgantown User Fee Airport, WV (1484)',
  PHILADELPHIA_INTL_AIRPORT_PA: 'Philadelphia International Airport (1485)',
  HARTFORD_USER_FEE_AIRPORT_CT: 'Hartford User Fee Airport, CT (1487)',
  ABINGDON_USER_FEE_AIRPORT_VA: 'Abingdon User Fee Airport, VA (1489)',
  PORTSMOUTH_USER_FEE_AIRPORT_VA: 'Portsmouth User Fee Airport, VA (1490)',
  RICHMOND_USER_FEE_AIRPORT_VA: 'Richmond User Fee Airport, VA (1491)',
  SEATTLE_WA: 'Seattle, WA (3001)',
  PORTLAND_OR: 'Portland, OR (3002)',
  TACOMA_WA: 'Tacoma, WA (3003)',
  SPOKANE_WA: 'Spokane, WA (3004)',
  ANACORTES_WA: 'Anacortes, WA (3005)',
  FRIDAY_HARBOR_WA: 'Friday Harbor, WA (3006)',
  PORT_ANGELES_WA: 'Port Angeles, WA (3007)',
  BLAINE_WA: 'Blaine, WA (3008)',
  SUMAS_WA: 'Sumas, WA (3013)',
  LONGVIEW_WA: 'Longview, WA (3014)',
  VANCOUVER_WA: 'Vancouver, WA (3016)',
  POINT_ROBERTS: 'Point Roberts, WA (3017)',
  KENMORE_AIR_HARBOR: 'Kenmore Air Harbor, WA (3018)',
  OROVILLE_AREA_PORT: 'Oroville Area Port, WA (3019)',
  FRONTIER: 'Frontier, WA (3020)',
  SPOKANE: 'Spokane, WA (3022)',
  LYNDEN: 'Lynden, WA (3023)',
  METALINE_FALLS: 'Metaline Falls, WA (3025)',
  OLYMPIA: 'OLYMPIA, WA (3026)',
  NEAH_BAY: 'Neah Bay, WA (3027)',
  TACOMA_INTERNATIONAL_AIRPORT: 'Tacoma International Airport, Seattle, WA (3029)',
  UPS_SEATTLE: 'UPS, SEATTLE, WA (3071)',
  GRANT_COUNTY_AIRPORT: 'Grant County Airport, Moses Lake, WA (3082)',
  JUNEAU: 'Juneau, AK (3101)',
  KETCHIKAN: 'Ketchikan, AK (3102)',
  SKAGWAY: 'Skagway, AK (3103)',
  ALCAN: 'Alcan, AK (3104)',
  WRANGELL: 'Wrangell, AK (3105)',
  DALTON_CACHE: 'Dalton Cache, AK (3106)',
  VALDEZ: 'Valdez, AK (3107)',
  FAIRBANKS: 'Fairbanks, AK (3111)',
  SITKA: 'Sitka, AK (3115)',
  ANCHORAGE: 'Anchorage, AK (3126)',
  KODIAK: 'Kodiak, AK (3127)',
  FEDERAL_EXPRESS_ANCHORAGE: 'FEDERAL EXPRESS, ANCHORAGE, AK (3195)',
  UPS_COURIER_ANCHORAGE: 'UPS Courier, Anchorage, AK (3196)',
  HONOLULU: 'Honolulu, HI (3201)',
  HILO: 'Hilo, HI (3202)',
  KAHULUI: 'Kahului, HI (3203)',
  NAWILIWILI_PORT_ALLEN: 'Nawiliwili-Port Allen (3204)',
  HONOLULU_INTERNATIONAL_AIRPORT: 'Honolulu International Airport, HI (3205)',
  KAILUA_KONA: 'KAILUA-KONA, HI (3206)',
  RAYMOND_MT: 'Raymond, MT (3301)',
  EASTPORT_ID: 'Eastport, ID (3302)',
  SALT_LAKE_CITY_UT: 'Salt Lake City, UT (3303)',
  GREAT_FALLS_MT: 'Great Falls, MT (3304)',
  BUTTE_AIRPORT_MT: 'Butte Airport, MT (3305)',
  TURNER_MT: 'Turner, MT (3306)',
  DENVER_CO: 'Denver, CO (3307)',
  PORTHILL_ID: 'Porthill, ID (3308)',
  SCOBEY_MT: 'Scobey, MT (3309)',
  SWEETGRASS_MT: 'Sweetgrass, MT (3310)',
  PIEGAN_MT: 'Piegan, MT (3316)',
  OPHEIM_MT: 'Opheim, MT (3317)',
  ROOSVILLE_MT: 'Roosville, MT (3318)',
  MORGAN_MT: 'Morgan, MT (3319)',
  WHITLASH_MT: 'Whitlash, MT (3321)',
  DEL_BONITA_MT: 'Del Bonita, MT (3322)',
  WILD_HORSE_MT: 'Wild Horse, MT (3323)',
  KALISPELL_MT: 'Kalispell, MT (3324)',
  WILLOW_CREEK_MT: 'Willow Creek, MT (3325)',
  BROOMFIELD_CO: 'Broomfield, CO (3383)',
  ENGLEWOOD_CO: 'Englewood, CO (3384)',
  GYPSUM_CO: 'Gypsum, CO (3385)',
  BOZEMAN_YELLOWSTONE_USER_FEE_AIRPORT: 'Bozeman Yellowstone User Fee Airport, MT (3386)',
  PEMBINA_ND: 'Pembina, ND (3401)',
  NOYES_MN: 'Noyes, MN (3402)',
  PORTAL_ND: 'Portal, ND (3403)',
  NECHE_ND: 'Neche, ND (3404)',
  ST_JOHN_ND: 'St. John, ND (3405)',
  NORTHGATE_ND: 'Northgate, ND (3406)',
  WALHALLA_ND: 'Walhalla, ND (3407)',
  HANNAH_ND: 'Hannah, ND (3408)',
  SARLES_ND: 'Sarles, ND (3409)',
  AMBROSE: 'Ambrose, ND (3410)',
  FARGO_HECTOR_INTERNATIONAL_AIRPORT: 'Fargo-Hector International Airport, ND (3411)',
  ANTLER: 'Antler, ND (3413)',
  SHERWOOD: 'Sherwood, ND (3414)',
  HANSBORO: 'Hansboro, ND (3415)',
  MAIDA: 'Maida, ND (3416)',
  FORTUNA: 'Fortuna, ND (3417)',
  WESTHOPE: 'Westhope, ND (3419)',
  NOONAN: 'Noonan, ND (3420)',
  CARBURY: 'Carbury, ND (3421)',
  DUNSEITH: 'Dunseith, ND (3422)',
  WARROAD: 'Warroad, MN (3423)',
  BAUDETTE: 'Baudette, MN (3424)',
  PINECREEK: 'Pinecreek, MN (3425)',
  ROSEAU: 'Roseau, MN (3426)',
  GRAND_FORKS: 'Grand Forks, ND (3427)',
  WINNIPEG_INTERNATIONAL_AIRPORT: 'Winnipeg International Airport (3428)',
  LANCASTER: 'Lancaster, MN (3430)',
  WILLISTON_SLOULIN_FIELD_INTERNATIONAL_AIRPORT: 'Williston-Sloulin Field International Airport, ND (3433)',
  MINOT_INTERNATIONAL_AIRPORT: 'Minot International Airport, ND (3434)',
  BORDER_PATROL_SECTOR_GRAND_FORKS: 'Border Patrol Sector, Grand Forks, ND (3450)',
  MINNEAPOLIS: 'Minneapolis, MN (3501)',
  SIOUX_FALLS: 'Sioux Falls, SD (3502)',
  DULUTH: 'Duluth, MN (3510)',
  OMAHA: 'Omaha, NE (3512)',
  DES_MOINES: 'Des Moines, IA (3513)',
  ROCHESTER: 'Rochester, MN (3581)',
  ASHLAND: 'Ashland, WI (3602)',
  INTERNATIONAL_FALLS: 'International Falls, MN (3604)',
  GRAND_PORTAGE: 'Grand Portage, MN (3613)',
  MILWAUKEE: 'Milwaukee, WI (3701)',
  MARINETTE: 'Marinette, WI (3702)',
  GREEN_BAY: 'Green Bay, WI (3703)',
  MANITOWOC: 'Manitowoc, WI (3706)',
  SHEBOYGAN: 'Sheboygan, WI (3707)',
  RACINE: 'Racine, WI (3708)',
  APPLETON_INTERNATIONAL_AIRPORT: 'Appleton International Airport, WI (3781)',
  DETROIT: 'Detroit, MI (3801)',
  PORT_HURON: 'Port Huron, MI (3802)',
  SAULT_STE_MARIE: 'Sault Sainte Marie, MI (3803)',
  SAGINAW_BAY_CITY_FLINT: 'Saginaw/Bay City/Flint, MI (3804)',
  BATTLE_CREEK: 'Battle Creek, MI (3805)',
  GRAND_RAPIDS: 'Grand Rapids, MI (3806)',
  DETROIT_METRO_AIRPORT: 'Detroit Metro Airport (3807)',
  ESCANABA: 'Escanaba, MI (3808)',
  MUSKEGON: 'Muskegon, MI (3815)',
  DETOUR_CITY: 'Detour City, MI (3819)',
  OAKLAND_PONTIAC_AIRPORT_WATERFORD: 'Oakland-Pontiac Airport Waterford, MI (3881)',
  WILLOW_RUN_AIRPORT_YPSILANTI: 'Willow Run Airport Ypsilanti, MI (3882)',
  CAPITAL_REGION_INTERNATIONAL_AIRPORT_LANSING: 'Capital Region International Airport Lansing, MI (3883)',
  CHICAGO: 'Chicago, IL (3901)',
  PEORIA: 'Peoria, IL (3902)',
  DAVENPORT: 'Davenport, IA (3908)',
  ROCKFORD: 'Rockford, IL (3909)',
  MIDWAY_INTERNATIONAL_AIRPORT_CHICAGO: 'Midway International Airport, Chicago, IL (3910)',
  TNT_EXPRESS_CONSIGNMENT_CHICAGO: 'TNT EXPRESS CONSIGNMENT, CHICAGO, IL (3971)',
  WAUKEGAN: 'Waukegan, IL (3981)',
  WHEELING_AIRPORT: 'Wheeling Airport (3983)',
  WEST_CHICAGO_AIRPORT: 'West Chicago Airport (3984)',
  DECATUR_AIRPORT: 'Decatur Airport (3985)',
  NIPPON_COURIER_HUB_CHICAGO: 'Nippon Courier Hub, Chicago, IL (3991)',
  CLEVLAND: 'Clevland, OH (4101)',
  CINCINNATI: 'Cincinnati, OH (4102)',
  COLUMBUS: 'Columbus, OH (4103)',
  DAYTON: 'Dayton, OH (4104)',
  TOLEDO_SANDUSKY: 'Toledo-Sandusky, OH (4105)',
  ERIE: 'Erie, PA (4106)',
  INDIANAPOLIS: 'Indianapolis, IN (4110)',
  LOUISVILLE: 'Louisville, KY (4115)',
  EVANSVILLE: 'Evansville, IN (4116)',
  HURON: 'Huron, OH (4117)',
  ASHTABULA: 'Ashtabula, OH (4122)',
  BURLINGTON_AIR_EXPRESS_TOLEDO: 'Burlington Air Express, Toledo, OH (4170)',
  WILMINGTON: 'Wilmington, OH (4181)',
  RICKENBACKER_AIRPORT_COLUMBUS: 'Rickenbacker Airport, Columbus, OH (4182)',
  FORT_WAYNE: 'Fort Wayne, IN (4183)',
  LEXINGTON: 'Lexington, KY (4184)',
  HULMAN_REGIONAL_AIRPORT_TERRE_HAUTE: 'Hulman Regional Airport, Terre Haute, IN (4185)',
  EMERY_COURIER_VANDALIA: 'Emery Courier, Vandalia, OH (4195)',
  UPS_COURIER_LOUISVILLE: 'UPS Courier, Louisville, KY (4196)',
  DHL_COURIER_CINCINNATI: 'DHL Courier, Cincinnati, OH (4197)',
  FEDEX_COURIER_HUB_INDIANAPOLIS: 'Federal Express Hub, Indianapolis, IN (4198)',
  KANSAS_CITY: 'Kansas City (4501)',
  ST_LOUIS: 'St. Louis, MO (4503)',
  WICHITA: 'Wichita, KS (4504)',
  SPRINGFIELD: 'Springfield, MO (4505)',
  SPIRIT_OF_ST_LOUIS_AIRPORT: 'Spirit of St.Louis Aiport (4506)',
  MASCOUAH_AIRPORT: 'Mascoutah Airport (4581)',
  NEWARK: 'Newark, NJ (4601)',
  PERTH_AMBOY: 'Perth Amboy, NJ (4602)',
  MORRISTOWN_AIRPORT: 'Morristown Airport, NJ (4681)',
  JOHN_F_KENNEDY_INTERNATIONAL_AIRPORT: 'John F Kennedy International Airport, NY (4701)',
  NYACC_JAMAICA: 'NYACC, Jamaica, NY (4771)',
  DHL_AIRWAYS_JAMAICA: 'DHL Airways, Jamaica, NY (4772)',
  EMERY_WORLDWIDE_JAMAICA: 'Emery Worldwide, Jamaica, NY (4773)',
  AIR_FRANCE_JAMAICA: 'Air France, Jamaica, NY (4774)',
  TNT_SKYPAK_JAMAICA: 'TNT Skypak, Jamaica, NY (4778)',
  AGUADILLA: 'Aguadilla, PR (4901)',
  FAJARDO: 'Fajardo, PR (4904)',
  HUMACAO: 'Humacao, PR (4906)',
  MAYAGUEZ: 'Mayaguez, PR (4907)',
  PONCE: 'Ponce, PR (4908)',
  SAN_JUAN: 'San Juan, PR (4909)',
  LUIS_MUNOZ_MARIN_INTERNATIONAL_AIRPORT: 'Luis Munoz Marin International Airport (4913)',
  PORT_CHARLOTTE: 'Port Charlotte, VI (5101)',
  CRUZ_BAY: 'Cruz Bay, VI (5102)',
  SAINT_CROIX: 'Saint Croix, VI (5104)',
  MIAMI_SEAPORT: 'Miami Seaport, FL (5201)',
  KEY_WEST: 'Key West, FL (5202)',
  FORT_LAUDERDALE: 'Fort Lauderdale, FL (5203)',
  WEST_PALM_BEACH: 'West Palm Beach (5204)',
  FORT_PIERCE: 'Fort Pierce, FL (5205)',
  MIAMI_INTERNATIONAL_AIRPORT: 'Miami International Airport, FL (5206)',
  FORT_LAUDERDALE_INTERNATIONAL_AIRPORT: 'Fort Lauderdale International Airport, FL (5210)',
  INTERNATIONAL_COURIER_ASSOCIATION_MIAMI: 'International Courier Association, Miami, FL (5270)',
  UPS_MIAMI_INTERNATIONAL_AIRPORT: 'UPS Miami International Airport, Miami, FL (5295)',
  DHL_WORLDWIDE_EXPRESS_MIAMI: 'DHL WORLDWIDE EXPRESS, MIAMI, FL (5296)',
  FEDEX_COURIER_HUB_MIAMI: 'Fedex Courier Hub, Miami, FL (5297)',
  IBC_COURIER_HUB_MIAMI: 'IBC Courier Hub, Miami, FL (5298)',
  HOUSTON: 'Houston, TX (5301)',
  TEXAS_CITY: 'Texas City, TX (5306)',
  HOUSTON_INTERCONTINENTAL_AIRPORT: 'Houston Intercontinental Airport, TX (5309)',
  GALVESTON: 'Galveston, TX (5310)',
  FREEPORT: 'Freeport, TX (5311)',
  CORPUS_CHRISTI: 'Corpus Christi, TX (5312)',
  PORT_LAVACA_POINT_COMFORT: 'Port Lavaca/Point Comfort, TX (5313)',
  SUGAR_LAND: 'Sugar Land, TX (5381)',
  CONROE: 'Conroe, TX (5382)',
  STERLING: 'Sterling, VA (5401)',
  ALEXANDRIA: 'Alexandria, VI (5402)',
  DALLAS_FORT_WORTH: 'Dallas/Fort Worth, TX (5501)',
  AMARILLO: 'Amarillo, TX (5502)',
  LUBBOCK: 'Lubbock, TX (5503)',
  OKLAHOMA_CITY: 'Oklahoma City, OK (5504)',
  TULSA: 'Tulsa, OK (5505)',
  AUSTIN: 'Austin, TX (5506)',
  SAN_ANTONIO: 'San Antonio, TX (5507)',
  MIDLAND: 'Midland, TX (5582)',
  ALLIANCE_AIRPORT: 'Alliance Airport, TX (5583)',
  ADDISON_AIRPORT: 'Addison Airport, TX (5584)',
  MCKINNEY: 'Mckinney, TX (5585)',
  CBP_ST_CROIX_PRECLEARANCE: 'CBP St. Croix Preclearance, St. Croix, Virgin Islands (7404)',
  CBP_BERMUDA_PRECLEARANCE: 'CBP Bermuda Preclearance, Washington, DC (7421)',
  CBP_FREEPORT_PRECLEARANCE: 'CBP Freeport Preclearance, Washington, DC (7422)',
  TEST_DISTRICT: 'Test District (8888)',
  CUSTOMS_HQ: 'Customs HQ (9900)',
  UNKNOWN_PORT_LOCATION: 'Unknown Port Location (9997)',
  ZZZZZZZZZZZ: 'ZZZZZZZZZZZ (9999)',
};

const ACEShipmentType = {
  PAPS: 'Paps',
  IN_BOND: 'In Bond',
  QP_IN_BOND: 'Qp In Bond',
  SECTION_321: 'Section 321',
  SECTION_321_FDA: 'Section 321 FDA',
  GOODS_ASTRAY: 'Goods Astray',
  INTANGIBLES: 'Intangibles',
  FREE_OF_DUTY_CUSTOMS_FORM_7523: 'Free Of Duty Customs Form 7523',
  RETURNED_AMERICAN_PRODUCTS_CUSTOMS_FORM_3311: 'Returned American Products Customs Form 3311',
  PERSONAL_SHIPMENT_CUSTOMS_FORM_3299: 'Personal Shipment Customs Form 3299',
};

export {
  ACIEntryPort,
  ACIReleaseOffice,
  ACIShipmentType,
  ACEPortOfEntry,
  ACEShipmentType,
};
