export const THREE_DOTS_STRING = '...';

export const CLEAR_STORE = 'CLEAR_STORE';
export const LOCATION_FORM_TYPE_BILL_TO = 'BILL_TO';
export const BEFORE_ROUTE_CHANGE = 'BEFORE_ROUTE_CHANGE';
export const LOCATION_FORM_TYPE_BRANCH_LOCATION = 'BRANCH_LOCATION';
export const LOCATION_FORM_TYPE_CONTRACT_BILL_TO_LOCATION = 'CONTRACT_BILL_TO_LOCATION';
// WC constants
export const TEL_CHAT_MESSAGE = 'TEL_CHAT_MESSAGE';
// default autodial services
export const DIAL_SERVICE_DEFAULT = 'tel';
export const DIAL_SERVICE_SKYPE = 'skype';
export const DIAL_SERVICE_FACETIME = 'facetime';
// loads list group by
export const ORGANIZE_BY_ROUTE = 'ROUTE';
export const ORGANIZE_BY_TRUCK = 'TRUCK';
export const ORGANIZE_BY_DRIVER = 'DRIVER';
export const ORGANIZE_BY_STATUS = 'STATUS';
export const ORGANIZE_BY_TRAILER = 'TRAILER';
export const ORGANIZE_BY_CARRIER = 'CARRIER';
export const ORGANIZE_BY_BILL_TO = 'BILL_TO';
export const ORGANIZE_BY_BRANCH = 'ENTERPRISE';
export const ORGANIZE_BY_NO_GROUPING = 'NO_GROUPING';
export const ORGANIZE_BY_DISPATCHED_BY = 'DISPATCHED_BY';
export const ORGANIZE_BY_LAST_EVENT_DATE = 'LAST_EVENT_DATE';
export const ORGANIZE_BY_FIRST_EVENT_DATE = 'FIRST_EVENT_DATE';
export const ORGANIZE_BY_CLO_TRANSPORTATION_MODE = 'CLO_TRANSPORTATION_MODE';
export const ORGANIZE_BY_TEL_TRANSPORTATION_MODE = 'TEL_TRANSPORTATION_MODE';
// dispatch constants
export const DISPATCH_ACTION = 'DISPATCH';
export const CANCEL_DISPATCH_ACTION = 'CANCEL_DISPATCH';
// router
export const LOCATION_CHANGE_ACTION = '@@router/LOCATION_CHANGE';
// options
export const EMPTY_OPTION_OBJECT = { label: '', value: '' };
export const EMPTY_OPTION_NULLABLE_OBJECT = { label: null, value: null };
